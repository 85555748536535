import { useEffect, useState } from 'react'

// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Icon } from "@iconify/react";
import { List, ListItem, ListItemIcon, ListItemText, ListItemButton } from '@mui/material';

const DownloadVideo = ({ downloadOpen, setDownloadOpen, recordLinks }) => {

    console.log(recordLinks)
    const handleClose = () => {
        setDownloadOpen(false)
    }

    const handlePlay = (link) => {
        window.open(link, '_blank');
    }

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={downloadOpen} fullWidth maxWidth='xs' sx={{
                "& .MuiDialog-paper": {
                    borderRadius: "8px", // Adjust the value as needed
                },
            }}>
                <DialogTitle id='customized-dialog-title' sx={{ p: 1, backgroundColor: '#00669e', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ color: 'white', fontSize: '16px' }}>
                        {`Download Video`}
                    </Typography>
                    <IconButton
                        aria-label='close'
                        onClick={handleClose}
                        sx={{ color: 'white' }}
                    >
                        <Icon icon='mdi:close' />
                    </IconButton>
                </DialogTitle>


                <DialogContent dividers sx={{ p: 2 }}>

                    <List>
                        {recordLinks.map((link, index) => (
                            <ListItem
                                key={index}
                                sx={{ display: 'flex', alignItems: 'center', gap: 1, borderBottom: '1px solid #ddd', padding: '8px 0' }}
                                secondaryAction={
                                    <a
                                        href={link}
                                        download={link.split('/').pop()}  // This will set the file name based on the URL
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            onClick={() => handlePlay(link)}
                                            sx={{
                                                textTransform: 'none',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Download
                                        </Button>
                                    </a>
                                }
                            >

                                <ListItemButton role={undefined}
                                    // onClick={() => handlePlay(link)} 
                                    dense>
                                    {/* <a
                                        href={link}
                                        download={"Class Record.mp4"}
                                        style={{ textDecoration: 'none' }}
                                    > */}
                                        <ListItemIcon>
                                            <IconButton>
                                                <PlayCircleIcon color="primary" />
                                            </IconButton>
                                        </ListItemIcon>
                                        <ListItemText primary={`Video ${index + 1}`} />
                                    {/* </a> */}
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default DownloadVideo