// ** React Imports
import { useState, Fragment } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Badge from '@mui/material/Badge'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import MuiMenu from '@mui/material/Menu'
import MuiMenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { Chip } from '@mui/material'
import useLogout from '../hooks/logouthook';
// ** Icon Imports
import { Icon } from "@iconify/react";

// ** Third Party Components
import 'react-perfect-scrollbar/dist/css/styles.css'
import PerfectScrollbarComponent from 'react-perfect-scrollbar'

// ** Styled Menu component
const Menu = styled(MuiMenu)(({ theme }) => ({
    '& .MuiMenu-paper': {
        width: 380,
        overflow: 'hidden',
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    '& .MuiMenu-list': {
        padding: 0
    }
}))

// ** Styled MenuItem component
const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
    }
}))

// ** Styled PerfectScrollbar component
const PerfectScrollbar = styled(PerfectScrollbarComponent)({
    maxHeight: 344
})


// ** Styled component for the title in MenuItems
const MenuItemTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    flex: '1 1 100%',
    overflow: 'hidden',
    fontSize: '0.875rem',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(0.75)
}))

// ** Styled component for the subtitle in MenuItems
const MenuItemSubtitle = styled(Typography)({
    flex: '1 1 100%',
    overflow: 'hidden',
    whiteSpace: 'wrap',
    textOverflow: 'ellipsis',
    color: 'gray'
})

const ScrollWrapper = ({ children, hidden }) => {
    if (hidden) {
        return <Box sx={{ maxHeight: 349, overflowY: 'auto', overflowX: 'hidden' }}>{children}</Box>
    } else {
        return <PerfectScrollbar options={{ wheelPropagation: false, suppressScrollX: true }}>{children}</PerfectScrollbar>
    }
}



const NotificationDropdown = ({ notification, unreadCount, fetchNotification }) => {


    // ** States
    const [anchorEl, setAnchorEl] = useState(null)

    // ** Hook
    const hidden = useMediaQuery(theme => theme.breakpoints.down('lg'))
    const { handleLogout } = useLogout()

    let token = localStorage.getItem('token')

    const handleDropdownOpen = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleDropdownClose = async () => {
        setAnchorEl(null)
    }

    const handleReadoneNotify = async (id) => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/read-noti-one`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                noti_id: id
            })

        })

        if (response.status === 403 || response.status === 401) {
            let refreshToken = '';
            if (typeof localStorage !== 'undefined') {
                refreshToken = window.localStorage.getItem('refreshToken');
            }

            try {
                const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        refreshToken: refreshToken,
                    }),
                });

                if (tokenResponse.status === 200) {
                    const tokendata = await tokenResponse.json();
                    window.localStorage.setItem('token', tokendata.accessToken);
                    token = tokendata.accessToken;


                    return handleReadoneNotify(id);
                } else {
                    handleLogout();
                }
            } catch (error) {
                console.error('Error refreshing token:', error);
                handleLogout();
            }

            return;
        }
        if (response.status == 200) {
            fetchNotification()
            // setAnchorEl(null)
        } else {
            console.error('Error fetching notifications:', response.status);
        }

    }

    const handleReadAllNotify = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/read-noti`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                // notification_id: notification.id
            })

        })

        if (response.status === 403 || response.status === 401) {
            let refreshToken = '';
            if (typeof localStorage !== 'undefined') {
                refreshToken = window.localStorage.getItem('refreshToken');
            }

            try {
                const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        refreshToken: refreshToken,
                    }),
                });

                if (tokenResponse.status === 200) {
                    const tokendata = await tokenResponse.json();
                    window.localStorage.setItem('token', tokendata.accessToken);
                    token = tokendata.accessToken;

                    return handleReadAllNotify();
                } else {
                    handleLogout();
                }
            } catch (error) {
                console.error('Error refreshing token:', error);
                handleLogout();
            }

            return;
        }
        if (response.status == 200) {
            fetchNotification()
            // setAnchorEl(null)
        } else {
            console.error('Error fetching notifications:', response.status);
        }

    }

    return (
        <Fragment>
            <MenuItem>
                <IconButton color='inherit' aria-haspopup='true' onClick={handleDropdownOpen} aria-controls='customized-menu' sx={{ zIndex: 1 }}>
                    {unreadCount > 0 ? (
                        <Badge
                            badgeContent={unreadCount}
                            color="error"
                            sx={{
                                '& .MuiBadge-badge': { top: 4, right: 4 },
                            }}
                        >
                            <Icon icon="mdi:bell-outline" style={{ top: 4, right: 4 }} />
                        </Badge>
                    ) : (
                        <Icon icon="mdi:bell-outline" style={{ top: 4, right: 4 }} />
                    )}
                </IconButton>
            </MenuItem>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleDropdownClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem
                    disableRipple
                    disableTouchRipple
                    sx={{ cursor: 'default', userSelect: 'auto', backgroundColor: 'transparent !important' }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <Typography sx={{ cursor: 'text', fontWeight: 500 }}>Notifications</Typography>
                        <Chip
                            skin='light'
                            size='small'
                            color='primary'
                            label={`${unreadCount} New`}
                            sx={{ height: 20, fontSize: '0.75rem', fontWeight: 500, borderRadius: '10px' }}
                        />
                    </Box>
                </MenuItem>
                <ScrollWrapper hidden={hidden}>
                    {notification.map((notifi, index) => (
                        <MenuItem key={index} onClick={() =>handleReadoneNotify(notifi.id)} sx={{ backgroundColor: notifi.status === 'unread' ? '#e9eced' : null, ":hover": { backgroundColor: notifi.status === 'unread' ? '#e9eced' : null } }}>
                            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>

                                <Box sx={{ mx: 4, flex: '1 1', display: 'flex', overflow: 'hidden', flexDirection: 'column' }}>
                                    <MenuItemTitle>{notifi.title}</MenuItemTitle>
                                    <MenuItemSubtitle variant='body2'>{notifi.body}</MenuItemSubtitle>
                                </Box>

                            </Box>
                        </MenuItem>

                    ))}
                </ScrollWrapper>
                <MenuItem
                    disableRipple
                    disableTouchRipple
                    sx={{
                        py: 1.6,
                        borderBottom: 0,
                        cursor: 'default',
                        userSelect: 'auto',
                        backgroundColor: 'transparent !important',
                        borderTop: theme => `1px solid ${theme.palette.divider}`
                    }}
                >
                    <Button fullWidth variant='contained' onClick={handleReadAllNotify}>
                        Read All Notifications
                    </Button>
                </MenuItem>
            </Menu>
        </Fragment>
    )
}

export default NotificationDropdown
