import React from 'react';
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Avatar,
  Typography,
  Box,
  Stack,
  Paper,
  Badge,
  Button,
  TextField

} from '@mui/material';
import { styled } from '@mui/material/styles';
import NavbarLog from '../components/navbarlogged';
import Footer from '../components/footer';
import Common from '../components/common_part';
import { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import PhoneIcon from '@mui/icons-material/Phone';
import { useNavigate, useLocation } from 'react-router-dom';
import { Hidden } from '@mui/material';
import useLogout from '../hooks/logouthook';
import themeConfig from '../config/themeConfig';
import { Helmet } from "react-helmet";
import { Icon } from '@iconify/react/dist/iconify.js';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));


function AccountTopUp() {

  const [stu_Data, setStu_Data] = useState({
    stu_id: '',
    stu_fname: '',
    stu_lname: '',
    profile: '',
    acc_balance: '',
    phone_num: '',
    email: '',
    NIC: '',
    city: '',
    country: '',
    address: '',
    gender: '',
    birthday: ''
  });
  const [currency, setCurrency] = useState('');
  // const [stu_Data1, setStu_Data1] = useState(null);
  const studentID = localStorage.getItem('studentID');
  const [amount, setAmount] = useState('');
  const [errors, setErrors] = useState('');
  const [merchant_id, setMerchant_id] = useState('');
  const [contactNumber, setContactNumber] = useState('')

  const navigate = useNavigate();
  const { handleLogout } = useLogout()


  let token = ''
  if (typeof localStorage !== 'undefined') {
    token = window.localStorage.getItem('token')
  }


  useEffect(() => {
    if (studentID) {
      fetchStu_Data();
      system_setting()
      window.scrollTo(0, 0);
    }
  }, [studentID]);

  
  const fetchStu_Data = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student_profile`, {
        method: 'POST',
        body: JSON.stringify({ studentID }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 403 || response.status === 401) {
        let refreshToken = '';
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken');
        }

        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refreshToken: refreshToken,
            }),
          });

          if (tokenResponse.status === 200) {
            const tokendata = await tokenResponse.json();
            window.localStorage.setItem('token', tokendata.accessToken);
            token = tokendata.accessToken;


            return fetchStu_Data();
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
          handleLogout();
        }

        return;
      }

      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }

      const getData = await response.json();
      setStu_Data({
        stu_id: getData?.student?.stu_id || '',
        stu_fname: getData?.student?.stu_fname || '',
        stu_lname: getData?.student?.stu_lname || '',
        profile: getData?.student?.profile || '',
        acc_balance: getData?.student?.acc_balance || '',
        phone_num: getData?.student?.phone_num || '',
        email: getData?.student?.email || '',
        NIC: getData?.student?.NIC || '',
        city: getData?.student?.city || '',
        country: getData?.student?.country || '',
        address: getData?.student?.address || '',
        gender: getData?.student?.gender || '',
        birthday: getData?.student?.birthday || ''
      });
      setCurrency(getData?.currency?.currency || '');
    } catch (err) {
      console.error(err);
    }
  };

  const system_setting = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/system-setting`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const data = await response.json()
      if (response.status === 200) {

        setContactNumber(data.result.contact_num ? data.result.contact_num : '')
      }


    } catch (err) {
      console.error(err);
    }
  };

  // const handleAmountSet = (value) => {
  //   setAmount(value.toString());
  //   setErrors(''); 
  // };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    setErrors("")


    if (isNaN(value)) {
      setErrors("Please enter a valid amount");
    } else {
      setErrors('');
    }
    setAmount(value);
  };

  const handleEnter = (event) => {
    console.log(event.key.codePointAt(0));
    console.log("first", event.keyCode)
    console.log("second", event.code)
    console.log(event)
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const handleComposition = (event) => {
    console.log(event.type, event.data);
    if (event.type === "compositionend" && event.data === "") {
      console.log("Possibly a backspace was pressed");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors("");

    if (!amount) {
      setErrors("Please add an amount");
      return;
    }

    const numericAmount = parseInt(amount, 10);

    if (!(numericAmount)) {
      setErrors("Please enter a valid amount");
      return;
    }
    if (numericAmount < 1000 || numericAmount > 15000) {
      setErrors("You can Top-Up between 1000 and 15000 amount");
      return;
    }


    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/account_topup`, {
        method: 'POST',
        body: JSON.stringify({

          studentID,
          amount
        }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 403 || response.status === 401) {
        let refreshToken = '';
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken');
        }

        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refreshToken: refreshToken,
            }),
          });

          if (tokenResponse.status === 200) {
            const tokendata = await tokenResponse.json();
            window.localStorage.setItem('token', tokendata.accessToken);
            token = tokendata.accessToken;


            return handleSubmit(event);
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
          handleLogout();
        }

        return;
      } else {
        const data = await response.json();

        if (data.error) {
          setErrors(data.error);
        } else {
          // setStu_Data1(data);
          setAmount("");


          window.payhere.onCompleted = function onCompleted(orderId) {
            console.log("Payment completed. OrderID:" + orderId);
            window.location.reload();

          };

          window.payhere.onDismissed = function onDismissed() {

            console.log("Payment dismissed");
          };


          window.payhere.onError = function onError(error) {

            console.log("Error:" + error);
          };

          // console.log('stu_Data1.order_id',stu_Data1.order_id)


          const payment = {

            sandbox: `${process.env.REACT_APP_SANDBOX}`,

            merchant_id: data.merchantId,
            return_url: `${process.env.REACT_APP_PRODUCTION_URL}/account_topup`,
            cancel_url: `${process.env.REACT_APP_PRODUCTION_URL}/account_topup`,
            notify_url: `${process.env.REACT_APP_NOTIFY_URL}`,
            order_id: data.order_id.toString(),
            items: 'Account Topup',
            currency: data.currency.toString(),
            amount: amount.toString(),
            first_name: data.first_name.toString(),
            last_name: data.last_name.toString(),
            email: data.email.toString(),
            phone: data.phone.toString(),
            address: data.address.toString(),
            custom_1: data.studentID.toString(),
            city: data.city.toString(),
            country: 'Sri Lanka',
            hash: data.hash.toString(),
          };
          window.payhere.startPayment(payment);
        }
      }

      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }


    } catch (error) {
      console.error("Error fetching data: ", error);

    }
  };

  const handlePayment = async (value) => {
    setErrors('');

    if (!value) {
      setErrors("Please add an amount");
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/account_topup`, {
        method: 'POST',
        body: JSON.stringify({ studentID, amount: value.toString() }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 403 || response.status === 401) {
        // Handle token refresh and retry logic
        // ...
        return handlePayment(value);
      } else if (response.status === 200) {
        const data = await response.json();

        if (window.payhere) {
          window.payhere.onCompleted = function onCompleted(orderId) {
            console.log("Payment completed. OrderID:" + orderId);
            window.location.reload();
          };

          window.payhere.onDismissed = function onDismissed() {
            console.log("Payment dismissed");
          };

          window.payhere.onError = function onError(error) {
            console.log("Error:" + error);
          };

          const payment = {
            sandbox: `${process.env.REACT_APP_SANDBOX}`,
            merchant_id: data.merchantId,
            return_url: `${process.env.REACT_APP_PRODUCTION_URL}/account_topup`,
            cancel_url: `${process.env.REACT_APP_PRODUCTION_URL}/account_topup`,
            notify_url: `${process.env.REACT_APP_NOTIFY_URL}`,
            order_id: data.order_id.toString(),
            items: 'Account Topup',
            currency: data.currency.toString(),
            amount: value.toString(),
            first_name: data.first_name.toString(),
            last_name: data.last_name.toString(),
            email: data.email.toString(),
            phone: data.phone.toString(),
            address: data.address.toString(),
            custom_1: data.studentID.toString(),
            city: data.city.toString(),
            country: 'Sri Lanka',
            hash: data.hash.toString(),
          };
          window.payhere.startPayment(payment);

        } else {
          console.error("PayHere SDK is not loaded");
        }
      }

      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }

    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };


  return (
    <div className="home-page">
      <Helmet>
        <title>Account-TopUp - {themeConfig.templateName}</title>
        <meta name="description" content="This is the Account Top-Up page of your site." />
      </Helmet>
      <NavbarLog />
      <Container sx={{ py: 3, my: 4, minHeight: '100vh' }}>
        <Card sx={{ my: 3, boxShadow: { xs: 0, md: "rgba(76, 78, 100, 0.22) 0px 2px 10px 0px;" } }}>
          <CardContent sx={{ display: 'flex', justifyContent: 'center' }} >
            <Grid container spacing={0.2} sx={{ flexGrow: 1, justifyContent: 'space-around', mt: 0.2, gap: 1 }}>


              <Hidden smDown>
                <Common />
              </Hidden>


              <Grid xs={12} md={8.8} sx={{ mb: 2 }}>

                <div>
                  <Card sx={{ mb: 1 }}>
                    {/* <CardHeader title={<Typography sx={{display:'flex', justifyContent:'center', fontSize:20}}>Quick Top-Up</Typography>} /> */}
                    <CardHeader title={<Typography sx={{ fontSize: '15px' }}>Quick Top-Up</Typography>} sx={{ background: '#00669e', color: 'white', mb: 1 }} />
                    <CardContent>
                      <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Grid item
                          xs={12}
                          sm={5.5}
                          sx={{ mt: 1 }}
                        >
                          <Stack direction="row" justifyContent='space-between'>
                            <Button xs={12} sm={6} sx={{ borderRadius: 2, boxShadow: 2, display: 'flex', justifyContent: 'center', width: '45%' }}
                              onClick={() => handlePayment(1000)}>
                              <Stack sx={{ mt: 2, mb: 2 }}>
                                <Typography sx={{ fontSize: 16 }}>{currency} 1000</Typography>
                                <Divider />
                                <Typography sx={{ fontSize: 12 }}>Top-Up</Typography>
                              </Stack>
                            </Button>

                            <Button xs={12} sm={6} sx={{ borderRadius: 2, boxShadow: 2, display: 'flex', justifyContent: 'center', width: '45%' }}
                              onClick={() => handlePayment(1500)}>
                              <Stack sx={{ mt: 2, mb: 2 }}>
                                <Typography sx={{ fontSize: 16 }}>{currency} 1500</Typography>
                                <Divider />
                                <Typography sx={{ fontSize: 12 }}>Top-Up</Typography>
                              </Stack>
                            </Button>
                          </Stack>

                          <Stack direction="row" justifyContent='space-between' sx={{ mt: 1 }}   >
                            <Button xs={12} sm={6} sx={{ borderRadius: 2, boxShadow: 2, display: 'flex', justifyContent: 'center', width: '45%' }}
                              onClick={() => handlePayment(2000)}>
                              <Stack sx={{ mt: 2, mb: 2 }}>
                                <Typography sx={{ fontSize: 16 }}>{currency} 2000</Typography>
                                <Divider />
                                <Typography sx={{ fontSize: 12 }}>Top-Up</Typography>
                              </Stack>
                            </Button>

                            <Button xs={12} sm={6} sx={{ borderRadius: 2, boxShadow: 2, display: 'flex', justifyContent: 'center', width: '45%' }}
                              onClick={() => handlePayment(2500)}>
                              <Stack sx={{ mt: 2, mb: 2 }}>
                                <Typography sx={{ fontSize: 16 }}>{currency} 2500</Typography>
                                <Divider />
                                <Typography sx={{ fontSize: 12 }}>Top-Up</Typography>
                              </Stack>
                            </Button>
                          </Stack>

                          <Stack direction="row" justifyContent='space-between' sx={{ mt: 1 }}  >
                            <Button xs={12} sm={6} sx={{ borderRadius: 2, boxShadow: 2, display: 'flex', justifyContent: 'center', width: '45%' }}
                              onClick={() => handlePayment(3000)}>
                              <Stack sx={{ mt: 2, mb: 2 }}>
                                <Typography sx={{ fontSize: 16 }}>{currency} 3000</Typography>
                                <Divider />
                                <Typography sx={{ fontSize: 12 }}>Top-Up</Typography>
                              </Stack>
                            </Button>

                            <Button xs={12} sm={6} sx={{ borderRadius: 2, boxShadow: 2, display: 'flex', justifyContent: 'center', width: '45%' }}
                              onClick={() => handlePayment(5000)}>
                              <Stack sx={{ mt: 2, mb: 2 }}>
                                <Typography sx={{ fontSize: 16 }}>{currency} 5000</Typography>
                                <Divider />
                                <Typography sx={{ fontSize: 12 }}>Top-Up</Typography>
                              </Stack>
                            </Button>
                          </Stack>
                        </Grid>

                        <Grid item
                          xs={12}
                          sm={5.9}
                          sx={{ mt: 1 }}>
                          {/* <Card xs={12} sx={{ borderRadius: 2, boxShadow: 2, display: 'flex', justifyContent: 'left', width: '100%' }}>
                            <CardContent>
                              <Grid container direction="column" alignItems="left" sx={{ width: '100%' }}>
                                <Grid sx={{ display: 'flex', alignContent: 'flex-end' }}>
                                  <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', background: '#fafafa', borderRadius: 2, boxShadow: 'inset 0px 0px 3px rgba(0, 0, 0, 0.2)', p: 1.2 }}>
                                    <Typography sx={{   fontSize: {xs: 14,sm: 15,md: 18,lg: 20,}, fontWeight: 500, color: 'black', }}>Account Balance : {Number(stu_Data.acc_balance).toFixed(2)} </Typography>
                                    <Typography sx={{ ml: 0.5, pb: 0.2, fontSize: {xs: 14,sm: 15,md: 18,lg: 20,}, fontWeight: 500, color: '#888885', alignContent: 'flex-end' }}>{currency} </Typography>
                                  </Box>
                                </Grid>

                                <Box
                                  sx={{
                                    mt: 1,
                                    display: 'flex',
                                    gap: 1,
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    width: '100%',

                                  }}
                                >
                                  <TextField
                                    label="Amount"
                                    value={amount}
                                    onChange={handleAmountChange}
                                    onKeyDown={handleEnter}
                                    sx={{ width: { xs: '100%', sm: 100 } }}
                                  />
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{
                                      flex: 1,
                                      border: '1px solid black',
                                      borderRadius: 1,
                                      backgroundColor: '#101847',
                                    }}
                                    onClick={(event) => handleSubmit(event, studentID, amount)}
                                  >
                                    Pay with card
                                  </Button>
                                </Box>

                                {errors ?
                                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 0 }}>
                                    {errors}
                                  </Typography>
                                  : null
                                }

                                <Grid item xs={12} sx={{ mt: 3, mb: 1, display: 'flex', alignItems: 'center' }}>
                                  <a href="https://www.payhere.lk" target="_blank" rel="noopener noreferrer">
                                   
                                    <Box sx={{ display: 'flex' }} >
                                      <img src="https://box1.ozonedesk.info/upload/27Nov2024074045_PayHere-Logo.png" alt="PayHere" style={{ maxWidth: '70px', height: 'auto', marginRight: 5 }} />
                                      <Icon icon="logos:visa" width="1.6rem" height="1.4rem" style={{
                                        marginRight: 5, border: '2px solid #ccc',
                                        borderRadius: '4px',
                                        backgroundColor: '#fff',
                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                                      }} />
                                      <Icon icon="logos:mastercard" width="1.6rem" height="1.4rem" style={{
                                        marginRight: 5, border: '2px solid #ccc',
                                        borderRadius: '4px',
                                        backgroundColor: '#fff',
                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                                      }} />
                                      <Icon icon="simple-icons:americanexpress" width="1.6rem" height="1.4rem" style={{
                                        marginRight: 5, border: '2px solid #ccc',
                                        borderRadius: '4px',
                                        backgroundColor: '#fff',
                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                                      }} />
                                      <Icon icon="noto:bank" width="1.6rem" height="1.4rem" style={{
                                        marginRight: 5, border: '2px solid #ccc',
                                        borderRadius: '4px',
                                        backgroundColor: '#fff',
                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                                      }} />
                                    </Box>
                                  </a>
                                </Grid>

                              </Grid>
                            </CardContent>
                          </Card> */}
                          <Card xs={12} sx={{ borderRadius: 2, boxShadow: 2, width: '100%' }}>
                            <CardContent>

                              <Box
                                sx={{
                                  width: '100%',
                                  background: '#fafafa',
                                  borderRadius: 2,
                                  boxShadow: 'inset 0px 0px 3px rgba(0, 0, 0, 0.2)',
                                  p: 1.2,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: { xs: 14, sm: 20 },
                                    fontWeight: '500',
                                    color: 'black',
                                  }}
                                >
                                  Account Balance : {Number(stu_Data.acc_balance).toFixed(2)}{' '}
                                  <Typography
                                    component="span"
                                    sx={{
                                      fontSize: { xs: 12, sm: 15 },
                                      fontWeight: '500',
                                      color: '#888885',
                                    }}
                                  >
                                    {currency}
                                  </Typography>
                                </Typography>
                              </Box>

                              <Grid container spacing={2} sx={{ mt: 1.5 }} >
                                <Grid item xs={12} sm={5} md={6} >
                                  
                                  <TextField type='text' 
                                  inputProps={{
                                    inputMode: "numeric",
                                    pattern: "[0-9]*",
                                  }} 
                                  label="Amount" size="large" value={amount} onChange={handleAmountChange} onKeyDown={handleEnter} fullWidth onCompositionStart={handleComposition}
                                  onCompositionUpdate={handleComposition}
                                  onCompositionEnd={handleComposition}/>
                                  
                                </Grid>
                                <Grid item xs={12} sm={7} md={6} >
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ border: '1px solid black', borderRadius: 1, backgroundColor: '#101847', height: 56 }}
                                    onClick={(event) => handleSubmit(event, studentID, amount)}
                                    fullWidth
                                  >
                                    Pay with card
                                  </Button>
                                </Grid>

                                {errors ?
                                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 0, marginLeft: '16px' }}>
                                    {errors}
                                  </Typography>
                                  : null
                                }

                                <Grid item xs={12} sx={{ mt: 1.5, mb: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <a href="https://www.payhere.lk" target="_blank" rel="noopener noreferrer">

                                    <Box sx={{ display: 'flex' }} >
                                      <img src="https://box1.ozonedesk.info/upload/27Nov2024074045_PayHere-Logo.png" alt="PayHere" style={{ maxWidth: '70px', height: 'auto', marginRight: 5 }} />
                                      <Icon icon="logos:visa" width="1.6rem" height="1.4rem" style={{
                                        marginRight: 5, border: '2px solid #ccc',
                                        borderRadius: '4px',
                                        backgroundColor: '#fff',
                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                                      }} />
                                      <Icon icon="logos:mastercard" width="1.6rem" height="1.4rem" style={{
                                        marginRight: 5, border: '2px solid #ccc',
                                        borderRadius: '4px',
                                        backgroundColor: '#fff',
                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                                      }} />
                                      <Icon icon="simple-icons:americanexpress" width="1.6rem" height="1.4rem" style={{
                                        marginRight: 5, border: '2px solid #ccc',
                                        borderRadius: '4px',
                                        backgroundColor: '#fff',
                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                                      }} />
                                      <Icon icon="noto:bank" width="1.6rem" height="1.4rem" style={{
                                        marginRight: 5, border: '2px solid #ccc',
                                        borderRadius: '4px',
                                        backgroundColor: '#fff',
                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                                      }} />
                                    </Box>
                                  </a>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>

                      <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 4, gap: 1 }}>
                        <PhoneIcon sx={{ ml: 2 }} />
                        <Typography>Contact For Support:</Typography>
                        <Typography sx={{ fontSize: 18 }}>{contactNumber}</Typography>
                      </Grid>

                    </CardContent>
                  </Card>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

      </Container>
      <Footer />
    </div>
  );
}


export default AccountTopUp;
