const useSubscribe = () => {


    const handlepushNotification = (token) => {
        const PUBLIC_VAPID_KEY = 'BHvjHpHMtVBT7s5yxLFbxSqjWpZBJ6uCP_0q3mFUGlKXE2_9S2Z3K-Kl88DOfd1sfHVaB37dgIgVof3HjH1YoxY';

        if ('serviceWorker' in navigator && 'PushManager' in window) {
            // Check notification permission
            if (Notification.permission === 'granted') {
                // If permission is already granted, register the service worker and subscribe
                navigator.serviceWorker.register('/sw.js').then((registration) => {
                    console.log('Service Worker registered:', registration);

                    // Subscribe to push notifications
                    registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: PUBLIC_VAPID_KEY,
                    }).then((subscription) => {
                        console.log('Push Subscription:', subscription);

                        // Send subscription to the server
                        fetch(`${process.env.REACT_APP_BACKEND_URL}/update-subscribe`, {
                            method: 'POST',
                            body: JSON.stringify({
                                subscription,
                                token
                            }),
                            headers: {
                                'Content-Type': 'application/json',

                            },
                        });
                        console.log("old", subscription.endpoint);
                    }).catch((error) => {
                        console.error('Failed to subscribe:', error);
                    });
                });
            } 
        }
    };

    return { handlepushNotification };
};

export default useSubscribe;