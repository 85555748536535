import React from 'react';
import { useState, useEffect } from 'react';
import { Grid, Typography, MenuItem, Box } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from 'react-router-dom';
import { Icon } from "@iconify/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from '@mui/material/styles';

{/* <span style={{marginLeft: 5}}></span> */ }

function Footer() {

  const [links, setLinks] = useState({
    youtube: '',
    tiktok: '',
    facebook: '',
    instergram:'',
    company_name: '',
    company_web_url: ''
  });

  useEffect(() => {
    fetchSocialLinks();
  }, []);

  const theme = useTheme()
  const smAbove = useMediaQuery(theme.breakpoints.up("sm"));

  const fetchSocialLinks = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/system-setting`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.result) {
        // Update the state with the links
        setLinks({
          youtube: data.result.youtube,
          tiktok: data.result.tiktok,
          facebook: data.result.facebook,
          instergram:data.result.instergram,
          company_name: data.result.company_name,
          company_web_url: data.result.company_web_url
        });
      } else {
        console.warn('No data found:', data.message);
      }
    } catch (err) {
      console.error('Failed to fetch system settings:', err);
    }
  };


  return (
    <>
      {smAbove ? (
        <footer style={{
          background: 'linear-gradient(to right , #101847, #00669e)',
          color: '#fff',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          position: 'fixed',
          bottom: '0',
          width: '100%',
          padding: '1rem',
          zIndex: 6
        }}>
          <Grid container sx={{
            color: '#fff',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: '13px',
          }}>

            <Grid item xs={12} md='auto' sx={{
              // display: 'flex',
              display: { xs: 'none', md: 'flex' },
              justifyContent: { xs: 'center', md: 'flex-start' },
              alignItems: 'center',
            }}>
              <Link to={"/privacy_policy"} target='_blank' style={{ color: '#fff', marginRight: '0.5rem', textDecoration: 'none' }}>Privacy Policy </Link>
              <Typography sx={{ color: '#fff', marginRight: '0.5rem' }}>|</Typography>
              <Link to={"/T_C"} target='_blank' style={{ color: '#fff', textDecoration: 'none' }}>T & C</Link>
            </Grid>

            <Grid item xs={12} md='auto' sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              gap: '0.5rem',
            }}>
              <p style={{ margin: 0 }}>
                Copyright © All Rights Reserved By <Link to={links.company_web_url ?? ''} target='_blank' style={{ color: '#fff', textDecoration: 'none' }}>{links.company_name}</Link> | Made In
                <img
                  src="/images/slflag-emoji.png"
                  alt="flag"
                  style={{ width: '1.2rem', height: 'auto', verticalAlign: 'middle', margin: '0px 8px ' }}
                />
                With
                <img
                  src="/images/heart-emoji.png"
                  alt="heart"
                  style={{ width: '1.2rem', height: 'auto', verticalAlign: 'middle', margin: '0px 8px ' }}
                />
                | Platform By <Link to={'https://sparkglobal.lk'} target='_blank' style={{ color: '#fff', textDecoration: 'none' }}>Spark</Link>
              </p>

            </Grid>

            <Grid item xs={12} md='auto' sx={{
              display: { xs: 'none', md: 'flex' },
              justifyContent: { xs: 'center', md: 'flex-end' },
              alignItems: 'center',
              gap: '0.5rem',
            }}>
              <a href={links.youtube} target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>
                <YouTubeIcon />
              </a>
              <a href={links.tiktok} target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>
                <Icon icon="ant-design:tik-tok-filled" width="1.4rem" height="1.4rem" />
              </a>
              <a href={links.facebook} target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>
                <FacebookIcon />
              </a>
              <a href={links.instergram} target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>
                <InstagramIcon />
              </a>
            </Grid>


          </Grid>
        </footer>
      ) : (
        <footer style={{
          background: 'linear-gradient(to right , #101847, #00669e)',
          color: '#fff',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          width: '100%',
          padding: '1rem',
          zIndex: 6
        }}>
          <Grid container sx={{
            color: '#fff',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: '13px',
          }}>

            <Grid item xs={12} md='auto' sx={{
              // display: 'flex',
              display: { xs: 'none', md: 'flex' },
              justifyContent: { xs: 'center', md: 'flex-start' },
              alignItems: 'center',
            }}>
              <Link to={"/privacy_policy"} target='_blank' style={{ color: '#fff', marginRight: '0.5rem', textDecoration: 'none' }}>Privacy Policy </Link>
              <Typography sx={{ color: '#fff', marginRight: '0.5rem' }}>|</Typography>
              <Link to={"/T_C"} target='_blank' style={{ color: '#fff', textDecoration: 'none' }}>T & C</Link>
            </Grid>

            <Grid item xs={12} md='auto' sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              gap: '0.5rem',
            }}>
              <p style={{ margin: 0 }}>
                Copyright © All Rights Reserved By <Link to={links.company_web_url ?? ''} target='_blank' style={{ color: '#fff', textDecoration: 'none' }}>{links.company_name}</Link> | Made In
                <img
                  src="/images/slflag-emoji.png"
                  alt="flag"
                  style={{ width: '1.2rem', height: 'auto', verticalAlign: 'middle', margin: '0px 8px ' }}
                />
                With
                <img
                  src="/images/heart-emoji.png"
                  alt="heart"
                  style={{ width: '1.2rem', height: 'auto', verticalAlign: 'middle', margin: '0px 8px ' }}
                />
                | Platform By <Link to={'https://sparkglobal.lk'} target='_blank' style={{ color: '#fff', textDecoration: 'none' }}>Spark</Link>
              </p>

            </Grid>

            <Grid item xs={12} md='auto' sx={{
              display: { xs: 'none', md: 'flex' },
              justifyContent: { xs: 'center', md: 'flex-end' },
              alignItems: 'center',
              gap: '0.5rem',
            }}>
              <a href={links.youtube} target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>
                <YouTubeIcon />
              </a>
              <a href={links.tiktok} target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>
                <Icon icon="ant-design:tik-tok-filled" width="1.4rem" height="1.4rem" />
              </a>
              <a href={links.facebook} target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>
                <FacebookIcon />
              </a>
              <a href={links.instergram} target="_blank" rel="noopener noreferrer" style={{ color: '#fff' }}>
                <InstagramIcon />
              </a>
            </Grid>


          </Grid>
        </footer>
      )}
    </>

  );
}
export default Footer;