import { useRef, useEffect } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

// ** Icon Imports

// ** Third Party Components
import PerfectScrollbarComponent from "react-perfect-scrollbar";

// ** Utils Imports

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const MessageView = ({ allmessages }) => {
    const messagesEndRef = useRef(null);
    const theme = useTheme();

    const hidden = useMediaQuery(theme.breakpoints.down("lg"));

    let adminId = "";
    if (typeof localStorage !== "undefined") {
        adminId = window.localStorage.getItem("studentID");
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [allmessages]);

    const getFileExtension = (url) => {
        const filetype = url.split(".").pop().toLowerCase();
        return ["jpg", "jpeg", "png", "gif"].includes(filetype);
    };

    const extractFileName = (url) => {
        // Split the URL by the last underscore

        const parts = url.split('_');
        const lastPart = parts.pop(); // Get the part after the last underscore

        // Split the file name from the extension
        const [fileName, extension] = lastPart.split(/\.(?=[^\.]+$)/); // Split only at the last dot

        if (fileName?.length <= 20) {
            return lastPart; // If the file name is short, return it as is
        }

        // Slice the file name and add the extension back
        return fileName?.slice(0, 15) + '...' + '.' + extension;

    };

    const timeformat = date => {
        const inputDate = new Date(date);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);

        // Check if the date is today
        if (inputDate.toDateString() === today.toDateString()) {
            return new Date(inputDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        }

        // Check if the date is yesterday
        if (inputDate.toDateString() === yesterday.toDateString()) {
            return 'Yesterday' + ' ' + new Date(inputDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        }

        // For any other date, return the date and month
        const options = { day: 'numeric', month: 'long', hour: '2-digit', minute: '2-digit' }; // Example: 1 January

        return inputDate.toLocaleDateString(undefined, options);
    };

    const checkurl = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const url = urlRegex.test(text)
       
        return url
    }

    return (
        <div>
            <Box>
                <Box
                    sx={{
                        p: 2.5,
                        maxHeight: "100%",
                        overflowY: "auto",
                        overflowX: "hidden",
                    }}
                >
                    {allmessages.map((item, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: "flex",
                                flexDirection: item.sender != "student" ? "row" : "row-reverse",
                                mb: 3,
                            }}
                        >
                            <Box
                                className="chat-body"
                                sx={{ maxWidth: ["calc(100% - 3.75rem)", "75%", "65%"] }}
                            >
                                <Box sx={{ "&:not(:last-of-type)": { mb: 3.5 } }}>
                                    <div>
                                        {item.message && (
                                            <Box>
                                                {checkurl(item.message) ? (
                                                    <Box
                                                        component="a"  // Use the 'a' element instead of Link for external URLs
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href={item.message} // Ensure it has https:// prefix
                                                        sx={{
                                                            textDecoration: 'none' ,// Remove underline from the link
                                                            color:'#4461d7'
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                boxShadow: 1,
                                                                borderRadius: '10px',
                                                                maxWidth: "100%",
                                                                width: "fit-content",
                                                                fontSize: "0.875rem",
                                                                wordWrap: "break-word",
                                                                p: (theme) => theme.spacing(1.5, 2),
                                                                ml: item.sender == "student" ? "auto" : undefined,
                                                                borderTopLeftRadius:
                                                                item.sender != "student" ? 0 : undefined,
                                                                borderTopRightRadius:
                                                                    item.sender == "student" ? 0 : undefined,
                                                                color:
                                                                    item.sender == "student"
                                                                        ? "#82edff"
                                                                        : "#4461d7",
                                                                backgroundColor:
                                                                    item.sender == "student"
                                                                        ? "primary.main"
                                                                        : "background.paper",
                                                                
                                                            }}
                                                        >
                                                            {item.message}
                                                        </Typography>
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        dangerouslySetInnerHTML={{ __html: item.message }}
                                                        sx={{
                                                            boxShadow: 1,
                                                            borderRadius: '10px',
                                                            maxWidth: "100%",
                                                            width: "fit-content",
                                                            fontSize: "0.875rem",
                                                            wordWrap: "break-word",
                                                            p: (theme) => theme.spacing(1.5, 2),
                                                            ml: item.sender == "student" ? "auto" : undefined,
                                                            borderTopLeftRadius:
                                                            item.sender != "student" ? 0 : undefined,
                                                            borderTopRightRadius:
                                                                item.sender == "student" ? 0 : undefined,
                                                            color:
                                                                item.sender == "student"
                                                                    ? "common.white"
                                                                    : "text.primary",
                                                            backgroundColor:
                                                                item.sender == "student"
                                                                    ? "primary.main"
                                                                    : "background.paper",
                                                        }}
                                                    >
                                                        {/* {item.message} */}
                                                    </Box>
                                                )}
                                            </Box>

                                        )}
                                    </div>

                                    {item.URL && (
                                        <Box sx={{ mt: 1 }}>
                                            {getFileExtension(item.URL) ? (
                                                <Box sx={{ width: 300, height: 250 }}>
                                                    <a size="small" target="_blank" href={item.URL}>
                                                        <img
                                                            src={item.URL}
                                                            alt="welcome"
                                                            style={{
                                                                width: "100%",
                                                                height: "100%",
                                                                objectFit: "cover",
                                                            }} // Ensure image fills the container
                                                        />
                                                    </a>
                                                </Box>
                                            ) : (
                                                <Box
                                                    sx={{
                                                        boxShadow: 1,
                                                        px: 4,
                                                    }}
                                                // target="_blank"
                                                // component={Link}
                                                // href={item.URL}
                                                >
                                                    <Link to={item.URL} target='_blank' style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: 1,
                                                        textDecoration: 'none'
                                                    }} >
                                                        <IconButton size="small">
                                                            <InsertDriveFileIcon color="black" />
                                                        </IconButton>
                                                        <Typography>{extractFileName(item.URL)}</Typography>
                                                    </Link>
                                                </Box>
                                            )}
                                        </Box>
                                    )}
                                    <Box
                                        sx={{
                                            mt: 1,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent:
                                                item.send == adminId ? "flex-end" : "flex-start",
                                        }}
                                    >
                                        <Typography
                                            variant="caption"
                                            sx={{ color: "text.secondary" }}
                                        >
                                            {item.datetime ? timeformat(item.datetime) : ''}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                    <div ref={messagesEndRef} />
                </Box>
            </Box>
        </div>
    );
};

export default MessageView;
