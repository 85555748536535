import React from 'react';
import {
    Container,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Button,
    Avatar,
    Typography,

} from '@mui/material';
import {
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    FormControl, MenuItem, Box, Select, TextField, Tooltip, Stack, Autocomplete
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import Star from '../star';
import { Link } from 'react-router-dom';
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { useNavigate } from 'react-router-dom';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import useLogout from '../../hooks/logouthook';
import { CircularProgress } from '@mui/material';
import { Icon } from "@iconify/react";

function CustomPopper(props) {
    return <Popper {...props} placement="top-start" />;
}

const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: '10px',
        border: '5px solid #00669e',
    },
}));

const UserEmailDetails = ({ setNavbarPhonedetailsOpen, navbarPhonedetailsOpen }) => {

    const [stu_fname, setStu_fname] = useState("");
    const [stu_lname, setStu_lname] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("Sri Lanka");
    const [errors, setErrors] = useState("");
    const [loading1, setLoading1] = useState(false);
    const [hotline, setHotline] = useState('')

    const navigate = useNavigate();
    const { handleLogout } = useLogout()

    const studentID = localStorage.getItem('studentID');

    let token = ''
    if (typeof localStorage !== 'undefined') {
        token = window.localStorage.getItem('token')
    }

    useEffect(() => {
        fetchSocialLinks()
    }, [])
    
    const capitalizeFirstLetter = (string) => {
        if (!string) return "";
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const handleCloseNvabarphoneDialog = () => {
        setNavbarPhonedetailsOpen(false);
        setErrors("");
    };

    const handlefirstname = (event) => {
        setStu_fname(event.target.value);
        setErrors("")
    };

    const handlelastname = (event) => {
        setStu_lname(event.target.value);
        setErrors("")
    };

    const handleemail = (event) => {
        setEmail(event.target.value);
        setErrors("")
    };

    const handleaddress = (event) => {
        setAddress(event.target.value);
        setErrors("")
    };

    const handlecity = (event) => {
        setCity(event.target.value);
        setErrors("")
    };

    const handlecountry = (event, newValue) => {
        setCountry(newValue);
        setErrors("")
    };

    const handleSubmitNavbarPhone = async (event) => {
        event.preventDefault();

        let newErrors = {};

        if (!validateEmail(email)) {
            newErrors.email = "Invalid email address";
        }

        if (!stu_fname) {
            newErrors.stu_fname = "Please enter the First Name";
        }

        if (!stu_lname) {
            newErrors.stu_lname = "Please enter the Last Name";
        }

        if (!email) {
            newErrors.email = "Please enter the Email";
        }

        if (!address) {
            newErrors.address = "Please enter the Address";
        }

        if (!city) {
            newErrors.city = "Please enter the City";
        }

        if (!country) {
            newErrors.country = "Please enter the Country";
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        } else {
            setErrors('');
            setLoading1(true);

            // Capitalize names
            const capitalizedFirstName = capitalizeFirstLetter(stu_fname);
            const capitalizedLastName = capitalizeFirstLetter(stu_lname);
            const capitalizedAddress = capitalizeFirstLetter(address);
            const capitalizedCity = capitalizeFirstLetter(city);
            const capitalizedCountry = capitalizeFirstLetter(country);

            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/profile_details`, {
                    method: 'POST',
                    body: JSON.stringify({
                        studentID,
                        stu_fname: capitalizedFirstName,
                        stu_lname: capitalizedLastName,
                        email,
                        address: capitalizedAddress,
                        city: capitalizedCity,
                        country: capitalizedCountry
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (response.status === 403 || response.status === 401) {
                    let refreshToken = '';
                    if (typeof localStorage !== 'undefined') {
                        refreshToken = window.localStorage.getItem('refreshToken');
                    }

                    try {
                        const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                refreshToken: refreshToken,
                            }),
                        });

                        if (tokenResponse.status === 200) {
                            const tokendata = await tokenResponse.json();
                            window.localStorage.setItem('token', tokendata.accessToken);
                            token = tokendata.accessToken;


                            return handleSubmitNavbarPhone(event);
                        } else {
                            handleLogout();
                        }
                    } catch (error) {
                        console.error('Error refreshing token:', error);
                        handleLogout();
                    }

                    return;
                }


                const data = await response.json();

                if (response.status === 200) {
                    setLoading1(false);
                    if (window.gtag_report_conversion) {
                        console.log("check script")
                        window.gtag_report_conversion();
                    } else {
                        console.warn("gtag_report_conversion function is not defined.");
                    }
                    setStu_fname("");
                    setStu_lname("");
                    setEmail("");
                    setAddress("");
                    setCity("");
                    setCountry("");
                    setErrors('');
                    setNavbarPhonedetailsOpen(false)
                    navigate("/dashboard");
                } else {
                    setLoading1(false);
                    setErrors({ ...errors, email: "This email is already registered. Please use another email." });
                }
                setLoading1(false);

            } catch (error) {
                setLoading1(false);
                setErrors("Failed to update profile. Please try again.");
            }
        }
    };


    const validateEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

    };

    const countries = [
        "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina",
        "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados",
        "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana",
        "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", "Cameroon",
        "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo, Democratic Republic of the",
        "Congo, Republic of the", "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark",
        "Djibouti", "Dominica", "Dominican Republic", "East Timor", "Ecuador", "Egypt", "El Salvador",
        "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland", "France",
        "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea",
        "Guinea-Bissau", "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia",
        "Iran", "Iraq", "Ireland", "Israel", "Italy", "Ivory Coast", "Jamaica", "Japan", "Jordan",
        "Kazakhstan", "Kenya", "Kiribati", "Korea, North", "Korea, South", "Kosovo", "Kuwait", "Kyrgyzstan",
        "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania",
        "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands",
        "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro",
        "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand",
        "Nicaragua", "Niger", "Nigeria", "North Macedonia", "Norway", "Oman", "Pakistan", "Palau",
        "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar",
        "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines",
        "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles",
        "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa",
        "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria",
        "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia",
        "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom",
        "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam",
        "Yemen", "Zambia", "Zimbabwe"
    ];

    const fetchSocialLinks = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/system-setting`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
          });
    
    
          const data = await response.json();
          if (response.status == 200) {
            // Update the state with the links
            setHotline(data.result.contact_num ? data.result.contact_num : '');
          } else {
            console.warn('No data found:', data.message);
          }
        } catch (err) {
          console.error('Failed to fetch system settings:', err);
        }
      };
    return (
        <div>
            <CustomDialog open={navbarPhonedetailsOpen} onClose={handleCloseNvabarphoneDialog} fullWidth>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', color: 'black', alignItems: 'center' }}>
                    <DialogTitle sx={{ ml: 1, fontSize: { xs: '17px', sm: '20px' } }} >Please Enter your Details</DialogTitle>
                    <Tooltip title="Close">
                        <CloseIcon sx={{ mr: 3, cursor: 'pointer' }} onClick={handleCloseNvabarphoneDialog} />
                    </Tooltip>
                </Box>

                <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
                    <form

                        style={{
                            width: "100%",
                            backgroundColor: "linear-gradient(to right , #101847, #00669e",
                            color: "white"
                        }}
                        onSubmit={handleSubmitNavbarPhone}

                    >
                        <Grid container spacing={1} sx={{ px: 1 }}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="stu_fname"
                                    name="firstName"
                                    label="First Name"
                                    size="large"
                                    sx={{
                                        backgroundColor: "white",
                                        borderRadius: "10px !important",


                                    }}
                                    fullWidth
                                    value={stu_fname}
                                    onChange={handlefirstname}

                                />
                                {errors.stu_fname ?
                                    <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                                        {errors.stu_fname}
                                    </Typography> : null
                                }
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="lastName"
                                    name="lastName"
                                    label="Last Name"
                                    size="large"
                                    sx={{
                                        backgroundColor: "white",
                                        borderRadius: "10px !important",

                                    }}
                                    fullWidth
                                    value={stu_lname}
                                    onChange={handlelastname}

                                />
                                {errors.stu_lname ?
                                    <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                                        {errors.stu_lname}
                                    </Typography> : null
                                }
                            </Grid>

                            <Grid item xs={12} >
                                <TextField
                                    id="email"
                                    name="email"
                                    label="Email"
                                    size="large"
                                    sx={{
                                        backgroundColor: "white",
                                        borderRadius: "10px !important",

                                    }}
                                    fullWidth
                                    value={email}
                                    onChange={handleemail}
                                />
                                {errors.email ?
                                    <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                                        {errors.email}
                                    </Typography> : null
                                }
                            </Grid >

                            <Grid item xs={12}>
                                <TextField
                                    id="address"
                                    name="House No, Lane Name"
                                    label="House No, Lane Name"
                                    size="large"
                                    sx={{
                                        backgroundColor: "white",
                                        borderRadius: "10px !important",
                                        mt: 1

                                    }}
                                    fullWidth
                                    value={address}
                                    onChange={handleaddress}

                                />
                                {errors.address ?
                                    <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                                        {errors.address}
                                    </Typography> : null
                                }
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    id="city"
                                    name="City"
                                    label="City"
                                    size="large"
                                    sx={{
                                        backgroundColor: "white",
                                        borderRadius: "10px !important",

                                    }}
                                    fullWidth
                                    value={city}
                                    onChange={handlecity}

                                />
                                {errors.city ?
                                    <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                                        {errors.city}
                                    </Typography> : null
                                }
                            </Grid>

                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    value={country}
                                    onChange={handlecountry}
                                    options={countries}
                                    sx={{ width: "100%" }}
                                    PopperComponent={CustomPopper} // Custom Popper to position dropdown above
                                    ListboxProps={{
                                        sx: {
                                            maxHeight: '200px', // Set height for dropdown content
                                            overflowY: 'auto', // Enable scrolling only inside the list
                                        },
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Country"
                                            sx={{
                                                backgroundColor: "white",
                                                borderRadius: "10px !important",
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item
                                mb={1}
                                mt={3}
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >

                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        color: "white",
                                        backgroundColor: "#0d2353",
                                        "&:hover": {
                                            backgroundColor: "#0d65c8",
                                        },
                                        "&.Mui-disabled": {
                                            backgroundColor: "#5BB4E4",
                                            color: "white",
                                        },
                                    }}
                                    size="md"
                                    disabled={loading1}
                                    startIcon={loading1 ? (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                                color: '#fff'
                                            }} />
                                    ) : null}
                                >
                                    {loading1 ? "Please Wait" : "Sign up"}
                                </Button>

                            </Grid>
                        </Grid>
                    </form>

                </DialogContent>
                <Typography sx={{ textAlign: 'center', fontSize: 16 }}>
                    Need a help?
                </Typography>

                <Box sx={{ mb: 4, fontSize: 12 }}>
                    <a href={`tel:${hotline}`} style={{ textDecoration: 'none', textAlign: 'center', color: '#0d2657' }}>

                        <Typography size="large" sx={{ fontSize: 16, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            HOTLINE {' '}
                            <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', ml: 2, mr: 1 }}>
                                <Icon icon="ic:baseline-phone" width="18" height="18" />
                            </Box>
                            {hotline}
                        </Typography>
                    </a>
                </Box>
            </CustomDialog>
        </div>
    )
}

export default UserEmailDetails
