import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Button,
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Grid
} from "@mui/material";
import Swal from 'sweetalert2';
import MenuIcon from "@mui/icons-material/Menu";
import GroupsIcon from "@mui/icons-material/Groups";
import "@fontsource/roboto/500.css";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import Drawer from '@mui/material/Drawer';
import ListItemIcon from '@mui/material/ListItemIcon';
import HomeIcon from '@mui/icons-material/Home';
import ListItemText from '@mui/material/ListItemText';
import LoginIcon from '@mui/icons-material/Login';
import Person2Icon from '@mui/icons-material/Person2';
import LoginPage from './loginPage/LoginPage'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { InputAdornment } from "@mui/material";
import UserDetails from "./loginPage/UserDetails";
import { NavLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import { NativeSelect } from '@mui/material';
import Popper from '@mui/material/Popper';
import Autocomplete from '@mui/material/Autocomplete';
import useLogout from "../hooks/logouthook";
import DescriptionIcon from '@mui/icons-material/Description';
import { CircularProgress } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from '@mui/material/styles';
import useSubscribe from "../hooks/subscribehook";


function CustomPopper(props) {
  return <Popper {...props} placement="top-start" />;
}

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '10px',
    border: '5px solid #00669e',
  },
}));


function Navbar({ setOpenUserDetails, setNavbarPhonedetailsOpen }) {
  const studentsID = localStorage.getItem('studentID');
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [openMobileNumberModal, setOpenMobileNumberModal] = React.useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState("");
  const [errors1, setErrors1] = useState("");
  const [otp, setOTP] = useState(new Array(4).fill(""));
  const [studentID, setStudentID] = useState("");
  const [openOTPModal, setOpenOTPModal] = React.useState(false);
  const [openPasswordModal, setOpenPasswordModal] = React.useState(false);
  // const [openProfileModal, setOpenProfileModal] = useState(false);
  const navigate = useNavigate("");
  const inputRefs = useRef([]);
  const [stu_fname, setStu_fname] = useState("");
  const [stu_lname, setStu_lname] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("Sri Lanka");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [mobileOrEmail, setMobileOrEmail] = useState('mobile')
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [enterPassword, setEnterPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [renewPassword, setReNewPassword] = useState('');
  const [shownewPassword, setShowNewPassword] = useState('');
  const [showrenewPassword, setShowReNewPassword] = useState('');
  const [newpasswordError, setNewPasswordError] = useState('');
  const [emailpassword, setEmailpassword] = useState('');
  const [showemailPassword, setShowemailPassword] = useState(false);
  const [emailPassworderror, setEmailPassworderror] = useState('');
  // const [openUserDetails, setOpenUserDetails] = useState(false);
  const { handleLogout } = useLogout();
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [openUserDetailsEmail, setOpenUserDetailsEmail] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [hotline, setHotline] = useState('')

  const {handlepushNotification} = useSubscribe()

  const theme = useTheme()
  const lgAbove = useMediaQuery(theme.breakpoints.up("lg"));

  let token = ''
  if (typeof localStorage !== 'undefined') {
    token = window.localStorage.getItem('token')
  }

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, [isKeyboardOpen]);

  useEffect(() => {
    if (openOTPModal) {
      setCountdown(60);
    }


    const timer = countdown > 0 && setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);


    return () => clearInterval(timer);
  }, [openOTPModal]);

  useEffect(() => {
    fetchSocialLinks()
  }, [])
  


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const validatePhoneNumber = (mobileNumber) => {
    return /^(0|\+94|94)?[0-9]{9}$/.test(mobileNumber);
  };

  // navModal
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  // mobileNumberModal

  const handleOpenMobileNumberModal = () => {
    setOpenMobileNumberModal(true);
  };

  const handleCloseMobileNumberModal = () => {
    setOpenMobileNumberModal(false);
    setErrors("");
    setMobileNumber("");
  };

  // const handleMobileNumberChange = (event) => {
  //   setMobileNumber(event.target.value); // Update mobileNumber state
  // };

  const handleMobileNumberChange = (event) => {
    let value = event.target.value;
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$/;
    setErrors("");

    if (!value) {
      setErrors("");
    }
    else if (!/^\+?[\d]+$/.test(value) && !emailRegex.test(value)) {
      setErrors("Invalid mobile number or email.");
    }
    else if (/^\+?[\d]+$/.test(value)) {
      if (value.startsWith("7")) {
        if (value.length !== 9) {
          setErrors("Invalid mobile number. Must be 9 digits when starting with '7'.");
        }
      } else if (value.startsWith("07")) {
        if (value.length !== 10) {
          setErrors("Invalid mobile number. Must be 10 digits when starting with '07'.");
        }
      } else if (value.startsWith("947")) {
        if (value.length !== 11) {
          setErrors("Invalid mobile number. Must be 11 digits when starting with '947'.");
        }
      } else if (value.startsWith("+947")) {
        if (value.length !== 12) {
          setErrors("Invalid mobile number. Must be 12 digits when starting with '+947'.");
        }
      } else {
        setErrors("Invalid mobile number format.");
      }
    }

    setMobileNumber(value);
  };

  const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
  const mobileRegex = /^\+?[\d]+$/;

  const handleCheck = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrors("");

    const inputValue = mobileNumber;

    if (emailRegex.test(inputValue)) {
      setErrors("");
      await handleSubmitemail(inputValue);

    } else if (mobileRegex.test(inputValue)) {
      console.log("mobile")
      setErrors("");
      await handleSubmit(event);

    } else {
      setErrors("Please enter a valid mobile number or email.");
    }
    setLoading(false);
  };

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      handleCheck(event);
    }
  };

  const handleloginEmail = () => {
    setMobileOrEmail('email')
  }

  const handleloginmobile = () => {
    setMobileOrEmail('mobile')
  }


  // otpModal




  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!mobileNumber) {
      setErrors("Please enter a Mobile number");
    } else if (!validatePhoneNumber(mobileNumber)) {
      setErrors("Invalid mobile number. Please enter a valid mobile number.");
    } else if (!(mobileNumber.length <= 14)) {
      setErrors("Invalid length");
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/student_login`,
          {
            method: "POST",
            body: JSON.stringify({
              mobileNumber,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );



        if (response.status === 403) {
          handleCloseMobileNumberModal(true);
          const data = await response.json();
          Swal.fire({
            icon: 'error',
            title: data.title,
            text: data.message,
            confirmButtonColor: '#00669e',
            confirmButtonText: 'OK',
            customClass: {
              container: 'custom-swal-container'
            }
          });
          return;
        }

        if (!response.ok) {
          throw new Error(`HTTP error: Status ${response.status}`);
        }

        const data = await response.json();

        if (
          data.message === "OTP updated successfully" ||
          data.message === "Mobile number and OTP inserted successfully"
        ) {
          setOpenMobileNumberModal(false);
          handleOpenOTPModal();
          setStudentID(data.stu_id);
          setErrors("");
        } else if (data.message == "Invalid") {
          setErrors("Invalid Phone Number");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
  };

  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      handleValue(event);
    }
  };

  const handleEnterKey1 = (event) => {
    setIsKeyboardOpen(false)
    handleValue(event);

  };

  const handleOpenOTPModal = () => {
    setOpenOTPModal(true);
    setIsKeyboardOpen(true)
    setTimeout(() => {
      if (inputRefs.current[0]) {
        inputRefs.current[0].focus();
      }
    }, 0);
  };

  const handleCloseOTPModal = () => {
    setOpenOTPModal(false);
    setErrors("");
    setMobileNumber("");
  };


  const setCaretToEnd = (element) => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(element);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
    element.focus();
  };

  const handleInput = (event, index) => {
    const value = event.target.value;

    // Check if the value is a valid number
    if (/^\d+$/.test(value)) {
      const newOtp = [...otp];

      // Extract only the last digit of the input
      newOtp[index] = value.slice(-1);

      // Update the OTP state with the new value
      setOTP(newOtp);

      // Focus the next text field if available
      if (value && index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      } else {
        setCaretToEnd(event.target);
      }
    }
  };

  const handleInput1 = (key) => {
    const value = key;

    // Check if the value is a valid number
    const newOtp = [...otp];

    // Extract only the last digit of the input
    newOtp[focusedIndex] = value

    // Update the OTP state with the new value
    setOTP(newOtp);

    // Focus the next text field if available
    if (value && focusedIndex < otp.length - 1) {
      inputRefs.current[focusedIndex + 1].focus();
      setFocusedIndex(focusedIndex + 1)
    }

  };

  const handleKeyDown = (index, event) => {
    console.log(event.key.codePointAt(0));
    console.log("first", event.keyCode)
    console.log("second", event.code)
    if (event.key === "Backspace" || event.key === "Escape") {
      const newOtp = [...otp];
      if (otp[index]) {
        // Clear current field
        newOtp[index] = '';
        setOTP(newOtp);
      } else if (index > 0) {
        // Move to the previous field if empty
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleKeyDown1 = () => {
    const newOtp = [...otp];
    if (otp[focusedIndex]) {
      // Clear current field
      newOtp[focusedIndex] = '';
      setOTP(newOtp);
    } else if (focusedIndex > 0) {
      // Move to the previous field if empty
      inputRefs.current[focusedIndex - 1].focus();
      setFocusedIndex(focusedIndex - 1)
    }

  };

  const handleClick = (index) => {
    const newOtp = [...otp];  // Create a copy of the OTP array
    newOtp[index] = "";       // Clear the value of the clicked field
    setOTP(newOtp);
    setFocusedIndex(index)
    inputRefs.current[index].focus();
    setIsKeyboardOpen(true)

  };

  const handleValue = async () => {
    setErrors("");
    setLoading(true);
    try {
      if (!otp) {
        setErrors("Please enter OTP.");
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/otp`, {
        method: "POST",
        body: JSON.stringify({
          studentID,
          otp: otp.join(""),
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      // if (!response.ok) {
      //   throw new Error(`HTTP error: Status ${response.status}`);
      // }

      const data = await response.json();

      if (response.status === 200) {
        setOTP(new Array(4).fill(""));
        setOpenOTPModal(false);

        window.localStorage.setItem('studentID', data.studentID)
        window.localStorage.setItem('token', data.accessToken)
        window.localStorage.setItem('refreshToken', data.refreshToken)

        handlepushNotification(data.accessToken)

        handleCloseOTPModal();

        // check neew user or not
        const studentResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student`, {
          method: "POST",
          body: JSON.stringify({ studentID: data.studentID }),
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${data.accessToken}`
          },
        });


        if (!studentResponse.ok) {
          throw new Error(`HTTP error: Status ${studentResponse.status}`);
        }

        const studentData = await studentResponse.json();

        if (studentData.temp == 0) {
          handleOpenProfileModal();
        } else {
          navigate("/dashboard");
        }

      } else {
        setLoading(false);
        setErrors(data.error);
      }

    } catch (error) {
      console.error("Error fetching data: ", error);
      setErrors("An error occurred while verifying OTP.");
    }
    setLoading(false);
  };

  const handleChange = () => {
    setOpenOTPModal(false);
    setOpenMobileNumberModal(true);
  };

  const handleSend = async () => {
    try {

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student_login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ mobileNumber }),
      });

      const data = await response.json();

      if (response.ok) {

        setOpenOTPModal(true);

      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: data.message || 'Failed to generate OTP. Please try again.',
          confirmButtonColor: '#00669e',
          confirmButtonText: 'OK',
          customClass: {
            container: 'custom-swal-container'
          }
        });
      }
    } catch (error) {

    }
  };

  const handleLoginSignup = async () => {
    console.log("Login successful!");
    handleCloseMobileNumberModal();
    setOpenOTPModal(true);
  };

  const handleOTPVerify = async () => {
    console.log("Login successful!");

    window.location.href = "/dashboard";
    // router.replace ('../dashboard.js')

    // } else {
    //   console.error('Login failed:', response.statusText);
    // }
  };


  //Password Model

  // const handleEnterKeyPassword = (event) => {
  //   if (event.key === "Enter") {
  //     handleEnterEmailPassword(event);
  //   }
  // };


  const handleOpenPasswordModal = () => {
    setOpenPasswordModal(true);

  };

  const handleSubmitemail = async (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
      setErrors("");
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/student_loginwithemail`,
        {
          method: "POST",
          body: JSON.stringify({ mobileNumber }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 403) {
        const data = await response.json();
        Swal.fire({
          icon: 'error',
          title: data.title,
          text: data.message,
          confirmButtonColor: '#00669e',
          confirmButtonText: 'OK',
          customClass: {
            container: 'custom-swal-container'
          }
        });
        return;
      }

      const data = await response.json();

      if (response.status === 404) {
        setEnterPassword('emailpassowrd')
        setOpenMobileNumberModal(false);
        handleOpenPasswordModal();

      }

      if (response.status === 400) {
        setEnterPassword("set")
        setOpenMobileNumberModal(false);
        handleOpenPasswordModal();
        setStudentID(data.stu_id);
      }

      if (response.status === 200) {
        setEnterPassword("enter")
        setOpenMobileNumberModal(false);
        handleOpenPasswordModal();
        setStudentID(data.stu_id);
        setEmailError("");
      }


    } catch (error) {
      console.error("Error fetching data: ", error);
      setEmailError("An error occurred while trying to log in. Please try again later.");
    }
  };

  const handleClosePasswordModal = () => {
    setOpenPasswordModal(false);
    setErrors("");
    setErrors1("");
    setPassword("");
    setNewPassword("");
    setEmailpassword("");
    setMobileNumber("");
  };

  const handlepassword = (event) => {
    setPassword(event.target.value);
    setErrors("");
    setErrors1("");
  };

  const handleEnterPassword = (event) => {
    if (event.key === "Enter") {
      handleNext(event);
    }
  };

  const handleNext = async () => {
    setErrors1("");
    setLoading(true);
    try {
      if (!password) {
        setErrors1("Please enter the password");
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student_verify_password`, {
        method: "POST",
        body: JSON.stringify({
          studentID,
          mobileNumber,
          password
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      // if (!response.ok) {
      //   throw new Error(`HTTP error: Status ${response.status}`);
      // }

      const data = await response.json();

      if (response.status === 200) {
        handleClosePasswordModal();

        window.localStorage.setItem('studentID', data.studentID)
        window.localStorage.setItem('token', data.accessToken)
        window.localStorage.setItem('refreshToken', data.refreshToken)


        handlepushNotification(data.accessToken)

        const studentResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student`, {
          method: "POST",
          body: JSON.stringify({ studentID: data.studentID }),
          headers: {
            "Content-Type": "application/json",
          },
        });

        // if (!studentResponse.ok) {
        //   throw new Error(`HTTP error: Status ${studentResponse.status}`);
        // }

        const studentData = await studentResponse.json();

        if (studentData.temp == 0) {
          setOpenUserDetails(true);
        } else {
          navigate("/dashboard");
        }
        setPassword("");

      } else if (response.status === 404) {
        setLoading(false);
        setErrors1(data.message);
      } else {
        setLoading(false);
        setErrors1(data.message);
      }



    } catch (error) {
      setErrors("An error occurred ");
      setLoading(false);
    }
  };

  const handleEnterSetPassword = (event) => {
    if (event.key === "Enter") {
      handleNewPasswordNextSubmit(event);
    }
  };

  const handleNewPasswordNextSubmit = async (event) => {
    event.preventDefault();

    setErrors1("");

    if (!newPassword) {
      setErrors1("Please enter a password");
      return
    }

    if (newPassword.length < 6) {
      setErrors1("The password must be equal or more than 6 characters.");
      return
    }

    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/NewPassword`,
        {
          method: "POST",
          body: JSON.stringify({
            studentID,
            mobileNumber,
            newPassword
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });


      // if (!response.ok) {
      //   throw new Error(`HTTP error: Status ${response.status}`);
      // }

      const data = await response.json();
      if (response.status === 200) {
        setOpenPasswordModal(false);
        setNewPasswordError("");

        window.localStorage.setItem('studentID', data.studentID)
        window.localStorage.setItem('token', data.accessToken)
        window.localStorage.setItem('refreshToken', data.refreshToken)

        handlepushNotification(data.accessToken)

      }

      const studentResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student`, {
        method: "POST",
        body: JSON.stringify({ studentID: data.studentID }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      // if (!studentResponse.ok) {
      //   throw new Error(`HTTP error: Status ${studentResponse.status}`);
      // }

      const studentData = await studentResponse.json();

      if (studentData.temp == 0) {
        setOpenUserDetails(true);
      } else {
        navigate("/dashboard");
      }
      setLoading(false);
      setPassword("");
    } catch (error) {
      console.error("Error fetching data: ", error);
      setErrors(error.message);
    }
    setLoading(false);
  };

  const handleEnterSetPasswordBoth = (event) => {
    if (event.key === "Enter") {
      handleEnterEmailPassword(event);
    }
  };

  const handleEnterEmailPassword = async () => {

    setErrors1("");

    if (!emailpassword) {
      setErrors1("Please enter a password");
      return
    }

    if (emailpassword.length < 6) {
      setErrors1("The password must be equal or more than 6 characters.");
      return
    }

    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/NewEmailPassword`,
        {
          method: "POST",
          body: JSON.stringify({
            mobileNumber,
            emailpassword
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });

      // if (!response.ok) {
      //   throw new Error(`HTTP error: Status ${response.status}`);
      // }

      const data = await response.json();
      if (response.status === 200) {

        window.localStorage.setItem('studentID', data.studentID)
        window.localStorage.setItem('token', data.accessToken)
        window.localStorage.setItem('refreshToken', data.refreshToken)
        handlepushNotification(data.accessToken)
        setLoading(false);
        setOpenPasswordModal(false);
        setOpenUserDetails(true);
        setNewPasswordError("");
        setPassword("");
        setOpenUserDetailsEmail(true);
      }

    } catch (error) {
      console.error("Error fetching data: ", error);
      setErrors(error.message);
    }
    setLoading(false);
  };

  const handleChangeEmailPassword = () => {
    setOpenPasswordModal();
    setOpenMobileNumberModal(true);
  };

  //Forgot Password Modal


  const handleChangeForgotPassword = () => {
    setOpenPasswordModal();
    navigate(`/forgot_password?semail=${JSON.stringify(mobileNumber)}`)
  };

  //Profile Model

  const countries = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina",
    "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados",
    "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana",
    "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", "Cameroon",
    "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo, Democratic Republic of the",
    "Congo, Republic of the", "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark",
    "Djibouti", "Dominica", "Dominican Republic", "East Timor", "Ecuador", "Egypt", "El Salvador",
    "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland", "France",
    "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea",
    "Guinea-Bissau", "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia",
    "Iran", "Iraq", "Ireland", "Israel", "Italy", "Ivory Coast", "Jamaica", "Japan", "Jordan",
    "Kazakhstan", "Kenya", "Kiribati", "Korea, North", "Korea, South", "Kosovo", "Kuwait", "Kyrgyzstan",
    "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania",
    "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands",
    "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro",
    "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand",
    "Nicaragua", "Niger", "Nigeria", "North Macedonia", "Norway", "Oman", "Pakistan", "Palau",
    "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar",
    "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines",
    "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles",
    "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa",
    "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria",
    "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia",
    "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom",
    "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam",
    "Yemen", "Zambia", "Zimbabwe"
  ];

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  };

  const handlefirstname = (event) => {
    setStu_fname(event.target.value);
    setErrors("")
  };

  const handlelastname = (event) => {
    setStu_lname(event.target.value);
    setErrors("")
  };

  const handleemail = (event) => {
    let value = event.target.value;
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$/
    setErrors("");

    if (!value) {
      setErrors("");
    }
    else if (!emailRegex.test(value)) {
      setErrors("Invalid email.");
    }
    setEmail(value);
  };

  const handleaddress = (event) => {
    setAddress(event.target.value);
    setErrors("")
  };

  const handlecity = (event) => {
    setCity(event.target.value);
    setErrors("")
  };

  const handlecountry = (event, newValue) => {
    setCountry(newValue);
    setErrors("")
  };

  const handleOpenProfileModal = () => {
    setNavbarPhonedetailsOpen(true);
  };

  const handleCloseProfileModal = () => {
    setNavbarPhonedetailsOpen(false);
    setErrors("");
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleSubmitProfile = async (event) => {
    event.preventDefault();
    let newErrors = {};

    if (!validateEmail(email)) {
      newErrors.email = "Invalid email address";
    }

    if (!stu_fname) {
      newErrors.stu_fname = "Please enter the First Name";
    }

    if (!stu_lname) {
      newErrors.stu_lname = "Please enter the Last Name";
    }

    if (!email) {
      newErrors.email = "Please enter the Email";
    }

    if (!address) {
      newErrors.address = "Please enter the Address";
    }

    if (!city) {
      newErrors.city = "Please enter the City";
    }

    if (!country) {
      newErrors.country = "Please enter the Country";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } else {
      setErrors('');

      // Capitalize names
      const capitalizedFirstName = capitalizeFirstLetter(stu_fname);
      const capitalizedLastName = capitalizeFirstLetter(stu_lname);
      const capitalizedAddress = capitalizeFirstLetter(address);
      const capitalizedCity = capitalizeFirstLetter(city);
      const capitalizedCountry = capitalizeFirstLetter(country);

      try {
        console.log(token)
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/profile_details`, {
          method: 'POST',
          body: JSON.stringify({
            studentID,
            stu_fname: capitalizedFirstName,
            stu_lname: capitalizedLastName,
            email,
            address: capitalizedAddress,
            city: capitalizedCity,
            country: capitalizedCountry
          }),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });



        if (response.status === 403 || response.status === 401) {
          let refreshToken = '';
          if (typeof localStorage !== 'undefined') {
            refreshToken = window.localStorage.getItem('refreshToken');
          }

          try {
            const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                refreshToken: refreshToken,
              }),
            });

            if (tokenResponse.status === 200) {
              const tokendata = await tokenResponse.json();
              window.localStorage.setItem('token', tokendata.accessToken);
              token = tokendata.accessToken;
              return handleSubmitProfile(event);
            } else {
              handleLogout();
            }
          } catch (error) {
            console.error('Error refreshing token:', error);
            handleLogout();
          }

          return;
        }

        const data = await response.json();

        if (response.status === 200) {
          if (window.gtag_report_conversion) {
            console.log("check script")
            window.gtag_report_conversion();
          } else {
            console.warn("gtag_report_conversion function is not defined.");
          }
          setStu_fname("");
          setStu_lname("");
          setEmail("");
          setAddress("");
          setCity("");
          setCountry("");
          setErrors('');
          handleCloseProfileModal();
          // handleOpenTimeSlotModal(t_id); 
          navigate("/dashboard");
        } else {
          setErrors({ ...errors, email: "This email is already registered. Please use another email." });
        }

      } catch (error) {
        setErrors("Failed to update profile. Please try again.");
      }
    }
  };

  //ProfileEmail Model

  const handlefirstnameEmail = (event) => {
    setStu_fname(event.target.value);
    setErrors("")
  };

  const handlelastnameEmail = (event) => {
    setStu_lname(event.target.value);
    setErrors("")
  };

  const handlePhone = (event) => {
    let valuemob = event.target.value;
    setErrors({ phone: "" }); // Initialize the errors state
    setMobileError("");

    if (!valuemob) {
      setErrors({ phone: "" }); // Clear errors if input is empty
    } else if (!/^\+?[\d]+$/.test(valuemob)) {
      setErrors({ phone: "Invalid mobile number. Only digits and an optional '+' are allowed." });
    } else {
      if (valuemob.startsWith("7")) {
        if (valuemob.length !== 9) {
          setErrors({ phone: "Invalid mobile number. Must be 9 digits when starting with '7'." });
        }
      } else if (valuemob.startsWith("07")) {
        if (valuemob.length !== 10) {
          setErrors({ phone: "Invalid mobile number. Must be 10 digits when starting with '07'." });
        }
      } else if (valuemob.startsWith("947")) {
        if (valuemob.length !== 11) {
          setErrors({ phone: "Invalid mobile number. Must be 11 digits when starting with '947'." });
        }
      } else if (valuemob.startsWith("+947")) {
        if (valuemob.length !== 12) {
          setErrors({ phone: "Invalid mobile number. Must be 12 digits when starting with '+947'." });
        }
      } else {
        setErrors({ phone: "Invalid mobile number format." });
      }
    }

    setPhone(valuemob);
  };

  const handleaddressEmail = (event) => {
    setAddress(event.target.value);
    setErrors("")
  };

  const handlecityEmail = (event) => {
    setCity(event.target.value);
    setErrors("")
  };

  const handlecountryEmail = (event) => {
    setCountry(event.target.value);
    setErrors("")
  };

  const handleCloseProfileModalEmail = () => {
    setOpenUserDetailsEmail(false);
    setErrors("");
  };

  const capitalizeFirstLetterEmail = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleSubmitProfileEmail = async (event) => {
    event.preventDefault();
    let newErrors = {};


    if (!stu_fname) {
      newErrors.stu_fname = "Please enter the First Name";
    }

    if (!stu_lname) {
      newErrors.stu_lname = "Please enter the Last Name";
    }

    if (!phone) {
      newErrors.phone = "Please enter the Mobile Number";
    }

    if (!address) {
      newErrors.address = "Please enter the Address";
    }

    if (!city) {
      newErrors.city = "Please enter the City";
    }

    if (!country) {
      newErrors.country = "Please enter the Country";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } else {
      setErrors('');

      // Capitalize names
      const capitalizedFirstName = capitalizeFirstLetter(stu_fname);
      const capitalizedLastName = capitalizeFirstLetter(stu_lname);
      const capitalizedAddress = capitalizeFirstLetter(address);
      const capitalizedCity = capitalizeFirstLetter(city);
      const capitalizedCountry = capitalizeFirstLetter(country);

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/profile_details_Email`, {
          method: 'POST',
          body: JSON.stringify({
            studentID: studentsID,
            stu_fname: capitalizedFirstName,
            stu_lname: capitalizedLastName,
            phone,
            address: capitalizedAddress,
            city: capitalizedCity,
            country: capitalizedCountry
          }),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        const data = await response.json();

        if (response.status === 200) {
          if (window.gtag_report_conversion) {
            console.log("check script")
            window.gtag_report_conversion();
          } else {
            console.warn("gtag_report_conversion function is not defined.");
          }
          setStu_fname("");
          setStu_lname("");
          setPhone("");
          setAddress("");
          setCity("");
          setCountry("");
          setErrors('');
          handleCloseProfileModalEmail();
          // handleOpenTimeSlotModal(t_id);

        } else if (response.status === 400) {
          setMobileError(data.message);

        }

      } catch (error) {
        setErrors("Failed to update profile. Please try again.");
      }
    }
  };

  const handleNavigate = (item) => {
    // Navigate to the dynamic URL with the teacher's name and ID
    navigate(`/English_Teacher_${item.t_id}-${item.t_fname}.html`);
  };

  const handleCloseUserDetailsEmail = () => {
    setOpenUserDetailsEmail(false);
    setErrors("");
  }

  const fetchSocialLinks = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/system-setting`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });


      const data = await response.json();
      if (response.status == 200) {
        // Update the state with the links
        setHotline(data.result.contact_num ? data.result.contact_num : '');
      } else {
        console.warn('No data found:', data.message);
      }
    } catch (err) {
      console.error('Failed to fetch system settings:', err);
    }
  }; 

  return (
    <AppBar
      position="fixed"
      sx={{ background: "linear-gradient(to right , #101847, #00669e)" }}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters>

          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "flex-start",
              display: { xs: "flex", md: "none" },
            }}
          >

            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              >

                <AppBar position="static" sx={{ background: 'linear-gradient(to right , #101847, #00669e)' }}>
                  <Toolbar sx={{ justifyContent: 'space-between' }}>
                    <Link to="/" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                      <img src="/images/White.png" alt="Your Logo" style={{ width: 150, marginRight: 10 }} />
                    </Link>
                    <IconButton edge="end" onClick={toggleDrawer(false)}>
                      <CloseIcon />
                    </IconButton>
                  </Toolbar>
                </AppBar>

                <MenuItem onClick={handleCloseNavMenu}>
                  <Link to="/" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                    <ListItemIcon>
                      <Icon icon="ic:sharp-person-search" width="1.3rem" height="1.3rem" />
                    </ListItemIcon>
                    <ListItemText primary="Find a Teacher" />
                  </Link>
                </MenuItem>

                <MenuItem onClick={handleCloseNavMenu}>
                  <Link to="/teacher_signup" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                    <ListItemIcon>
                      <Person2Icon />
                    </ListItemIcon>
                    <ListItemText primary="Become a Teacher" />
                  </Link>
                </MenuItem>

                <MenuItem onClick={handleCloseNavMenu}>
                  <Link to="/community" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                    <ListItemIcon>
                      <GroupsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Community" />
                  </Link>
                </MenuItem>

                <MenuItem onClick={handleCloseNavMenu}>
                  <Link to="/privacy_policy" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary="Privacy Policy" />
                  </Link>
                </MenuItem>

                <MenuItem onClick={handleCloseNavMenu}>
                  <Link to="/T_C" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary="Terms & Conditions" />
                  </Link>
                </MenuItem>

                <MenuItem onClick={handleCloseNavMenu}>
                  <div
                    onClick={handleOpenMobileNumberModal}
                    style={{ display: 'flex', alignItems: 'center', color: 'black', textDecoration: 'none' }}
                  >
                    <ListItemIcon>
                      <LoginIcon />
                    </ListItemIcon>
                    <ListItemText primary="Login/SignUp" />
                  </div>
                </MenuItem>
              </Box>
            </Drawer>
            <IconButton
              size="large"
              aria-label="open drawer"
              onClick={toggleDrawer(true)}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>

          {!drawerOpen && (
            <Link to="/" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
              <img
                src="/images/White.png"
                alt="Your Logo"
                style={{ width: 150, marginRight: 10 }}
              />
            </Link>
          )}

          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "flex-end",
              display: { xs: "none", md: "flex" },
            }}
          >

            <MenuItem>
              <NavLink to="/" style={({ isActive }) => ({
                textDecoration: "none",
                color: "white",
                backgroundColor: isActive ? "#9a9a9a3d" : "transparent", // Change background color
                padding: "4px 10px", // Optional: Add padding for better visibility
                borderRadius: "8px"
              })}>
                <p>Find a Teacher</p>
              </NavLink>
            </MenuItem>

            {/* <MenuItem>
              <Link
                to="/teacher_signup"
                style={{ textDecoration: "none", color: "white" }}
              >
                <p>Become a Teacher</p>
              </Link>
            </MenuItem> */}

            <MenuItem>
              <NavLink to="/teacher_signup" style={({ isActive }) => ({
                textDecoration: "none",
                color: "white",
                backgroundColor: isActive ? "#9a9a9a3d" : "transparent", // Change background color
                padding: "4px 10px", // Optional: Add padding for better visibility
                borderRadius: "8px"
              })}>
                <p>Become a Teacher</p>
              </NavLink>
            </MenuItem>

            <MenuItem>
              <NavLink
                to="/community"
                style={({ isActive }) => ({
                  textDecoration: "none",
                  color: "white",
                  backgroundColor: isActive ? "#9a9a9a3d" : "transparent", // Change background color
                  padding: "4px 10px", // Optional: Add padding for better visibility
                  borderRadius: "8px"
                })}>
                <p style={{ margin: 0, fontFamily: "sans-serif" }}>Community</p>
              </NavLink>
            </MenuItem>

            <MenuItem>
              <Button
                style={{
                  color: "white",
                  borderRadius: "20px",
                  borderColor: "White",
                  borderWidth: "2px",
                  backgroundColor: "#101847",
                }}
                variant="outlined"
                onClick={handleOpenMobileNumberModal}
              >
                Student Login/SignUp
              </Button>
            </MenuItem>
          </Box>
        </Toolbar>


      </Container>

      <Dialog open={openMobileNumberModal} onClose={handleCloseMobileNumberModal} fullWidth maxWidth='sm'>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            background: "linear-gradient(to right , #101847, #00669e)",
            color: "white",
            mb: 2.5,
          }}
        >
          <DialogTitle>Login/Signup</DialogTitle>
          <Tooltip title="Close">
            <CloseIcon
              sx={{ mt: 2, mr: 2, cursor: "pointer" }}
              onClick={handleCloseMobileNumberModal}
            />
          </Tooltip>
        </Grid>

        <DialogContent>
          <DialogContentText sx={{ pb: 1 }}>
            Please Enter Your Mobile Number or Email
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="mobileNumber"
            label="Mobile Number/Email"
            type="text"
            fullWidth
            value={mobileNumber}
            onChange={handleMobileNumberChange}
            onKeyDown={handleEnter}
            // errors="please enter a valid number"
            error={!!errors}
            helperText={errors}
            placeholder="07XXXXXXXX / XXXX@XX.XX"
          />



        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>

          <Button
            sx={{
              size: 'large',
              type: "submit",
              display: "flex",
              justifyContent: "center",
              color: "white",
              backgroundColor: "#0d2353",
              "&:hover": {
                backgroundColor: "#0d65c8",
              },
              "&.Mui-disabled": {
                backgroundColor: "#5BB4E4",
                color: "white",
              },

              variant: "contained",
              borderRadius: "6px",
              marginBottom: "25px",
              px: 3
            }}
            onClick={handleCheck}
            disabled={loading}
            startIcon={loading ? (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                  color: '#fff'
                }} />
            ) : null}

          >
            {loading ? "Please Wait" : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openOTPModal} onClose={handleCloseOTPModal} fullWidth maxWidth='sm'>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            background: "linear-gradient(to right , #101847, #00669e)",
            color: "white",
            mb: 2,
          }}
        >
          <DialogTitle>OTP Verification</DialogTitle>
          <Tooltip title="Close">
            <CloseIcon
              sx={{ mt: 2, mr: 2, cursor: "pointer" }}
              onClick={handleCloseOTPModal}
            />
          </Tooltip>
        </Grid>

        <DialogContent>
          <DialogContent sx={{ borderRadius: "6px" }}>
            <DialogContentText
              sx={{
                textAlign: "center",
                varient: "modal-body",
                color: "black",
                mb: 1,
              }}
            >
              Please enter the OTP to verify your account
            </DialogContentText>
            <DialogContentText sx={{ mb: "1", textAlign: "center" }}>
              Code has been sent to {mobileNumber}
              <span
                style={{
                  marginLeft: 5,
                  color: "rgb(13 110 253)",
                  cursor: "pointer",
                }}
                onClick={handleChange}
              >
                Change
              </span>
            </DialogContentText>

            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                flexDirection: "row",
                marginTop: "20px",
                gap: 7
              }}
            >
              {lgAbove ? (
                <>
                  {otp.map((digit, index) => (
                    <TextField
                      key={index}
                      inputRef={(el) => (inputRefs.current[index] = el)}
                      value={digit}
                      onChange={(e) => handleInput(e, index)}
                      onClick={() => handleClick(index)}
                      onKeyDown={(e) => {
                        handleKeyDown(index, e);
                        handleEnterKey(e);
                      }}

                      inputProps={{
                        inputMode: 'none',
                        // readOnly: true, 
                        pattern: "[0-9]*",
                      }}

                      sx={{
                        width: 40, height: 40, textAlign: 'center', padding: '0px', "& .MuiInputBase-root": {
                          height: 40, // Ensures the input height
                          display: "flex",
                          alignItems: "center",
                          zIndex: 1600,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid gray", // Adjust border
                        },
                        "& .MuiInputBase-input": {
                          padding: 0, // Remove inner padding for precise size
                          textAlign: "center", // Centers the text
                        },
                      }}
                    />
                  ))}
                </>
              ) : (
                <>
                  {otp.map((digit, index) => (
                    <TextField
                      key={index}
                      inputRef={(el) => (inputRefs.current[index] = el)}
                      value={digit}
                      onChange={(e) => handleInput(e, index)}
                      onClick={() => handleClick(index)}
                      onKeyDown={(e) => {
                        handleKeyDown(index, e);
                        handleEnterKey(e);
                      }}

                      inputProps={{
                        inputMode: 'none',
                        readOnly: true, 
                        pattern: "[0-9]*",
                      }}

                      sx={{
                        width: 40, height: 40, textAlign: 'center', padding: '0px', "& .MuiInputBase-root": {
                          height: 40, // Ensures the input height
                          display: "flex",
                          alignItems: "center",
                          zIndex: 1600,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid gray", // Adjust border
                        },
                        "& .MuiInputBase-input": {
                          padding: 0, // Remove inner padding for precise size
                          textAlign: "center", // Centers the text
                        },
                      }}
                    />
                  ))}
                </>
              )}


            </Grid>
            {errors && (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
                <Typography
                  variant="caption"
                  sx={{ color: "red", textAlign: "center", fontSize: "14px" }}
                >
                  {errors}
                </Typography>
              </Box>
            )}

            <Box sx={{ padding: 2, textAlign: 'center', backgroundColor: '#F1F1F1', borderRadius: 1, mt: 2, display: { xs: 'block', lg: 'none' } }}>
              <Grid container spacing={1}>
                {['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'Clear'].map((key) => (
                  <Grid item xs={key === 'Clear' ? 8 : 4} key={key}>
                    <Button
                      variant="contained"
                      // color={key === 'Clear' ? 'error' : key === 'Enter' ? 'success' : 'primary'}
                      sx={{ backgroundColor: '#cfd9ddd1', ":hover": { backgroundColor: '#cfd9ddd1' }, color: '#413e3e', fontSize: '16px', minHeight: '40px' }}
                      fullWidth
                      onClick={() =>
                        key === 'Clear' ? handleKeyDown1() : key === 'Enter' ? handleEnterKey1() : handleInput1(key)
                      }
                    >
                      {key === 'Clear' ? <Icon icon="material-symbols-light:backspace" width="24" height="24" /> : key === 'Enter' ? <Icon icon="fluent:arrow-enter-left-24-regular" width="24" height="24" style={{ color: 'black' }} /> : key}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Box>

            <DialogActions sx={{ justifyContent: "center" }}>
              {/* <Button onClick={handleCloseOTP}>Cancel</Button>
          <Button component={Link} to="/dashboard" variant="contained" color="primary">Submit</Button> */}

              <Button
                sx={{
                  type: "submit",
                  color: "white",
                  backgroundColor: "#0d2353",
                  "&:hover": {
                    backgroundColor: "#0d65c8",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#5BB4E4",
                    color: "white",
                  },
                  variant: "contained",
                  mt: 2,
                  px: 4,
                  py: 1,
                  borderRadius: "6px",
                }}
                onClick={handleValue}
                disabled={loading}
                startIcon={loading ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                      color: '#fff'
                    }} />
                ) : null}
              >
                {loading ? "Please Wait" : "Verify"}
              </Button>
            </DialogActions>
          </DialogContent>
          <DialogContentText sx={{ my: 2, justifyContent: "center", textAlign: "center" }}>
            Didn't get the code?
            {countdown > 0 ? (
              <span style={{ marginLeft: 5, color: "gray" }}> Try Again in {countdown}s</span>
            ) : (
              <span
                style={{ marginLeft: 5, color: "rgb(13 110 253)", cursor: "pointer" }}
                onClick={() => {
                  handleSend();
                  setCountdown(60);
                }}
              >
                Try Again
              </span>
            )}
          </DialogContentText>
        </DialogContent>

        <Typography sx={{ textAlign: 'center', fontSize: 16 }}>
          Need a help?
        </Typography>

        <Box sx={{ mb: 4, fontSize: 12 }}>
          <a href={`tel:${hotline}`} style={{ textDecoration: 'none', textAlign: 'center', color: '#0d2657' }}>

            <Typography size="large" sx={{ fontSize: 16, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              HOTLINE {' '}
              <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', ml: 2, mr: 1 }}>
                <Icon icon="ic:baseline-phone" width="18" height="18" />
              </Box>
              {hotline}
            </Typography>
          </a>
        </Box>
      </Dialog>

      <Dialog open={openPasswordModal} onClose={handleClosePasswordModal} fullWidth maxWidth='sm'>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            background: "linear-gradient(to right , #101847, #00669e)",
            color: "white",
            mb: 2,
          }}
        >
          <DialogTitle>{enterPassword == "enter" ? "Enter Your Password" : "Set Your Password"}</DialogTitle>
          <Tooltip title="Close">
            <CloseIcon
              sx={{ mt: 2, mr: 2, cursor: "pointer" }}
              onClick={handleClosePasswordModal}
            />
          </Tooltip>
        </Grid>

        {enterPassword === "enter" ? (
          <DialogContent>
            <Typography>
              Email - {mobileNumber}
            </Typography>
            <DialogContentText sx={{ pb: 1 }}>
              Please Enter Your Password
            </DialogContentText>
            <TextField
              id="password"
              name="password"
              placeholder="Password"
              autoFocus
              type={showPassword ? 'text' : 'password'}
              size="large"
              sx={{
                backgroundColor: 'white',
                borderRadius: '10px !important',
              }}
              fullWidth
              error={!!errors1}
              helperText={errors1}
              value={password}
              onChange={handlepassword}
              onKeyDown={handleEnterPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* {errors1.password && (
              <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                {errors1.password}
              </Typography>
            )} */}
            <DialogContentText sx={{ textAlign: "right", mt: 2 }}>
              <span
                style={{
                  color: "rgb(13 110 253)",
                  cursor: "pointer",
                }}
                onClick={handleChangeForgotPassword}
              >
                Forgot Password ?
              </span>
            </DialogContentText>

            <DialogActions sx={{ justifyContent: "center" }}>
              <Button
                sx={{
                  type: "submit",
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#0d2353",
                  "&:hover": {
                    backgroundColor: "#0d65c8",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#5BB4E4",
                    color: "white",
                  },

                  variant: "contained",
                  borderRadius: "6px",
                  mt: 2,
                  px: 3
                }}
                onClick={handleNext}
                disabled={loading}
                startIcon={loading ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                      color: '#fff'
                    }} />
                ) : null}
              >
                {loading ? "Please Wait" : "Next"}
              </Button>
            </DialogActions>
            <DialogContentText sx={{ mt: 1, textAlign: "center" }}>
              <span
                style={{
                  color: "gray",
                  cursor: "pointer",
                }}
                onClick={handleChangeEmailPassword}
              >
                Back to login
              </span>
            </DialogContentText>
          </DialogContent>
        ) : enterPassword == "set" ? (
          <DialogContent>

            <Typography>
              Email - {mobileNumber}
            </Typography>
            <DialogContentText sx={{ pb: 1 }}>
              Please Set Your Password
            </DialogContentText>
            <TextField
              id="password"
              name="password"
              autoFocus
              placeholder="Password"
              type={shownewPassword ? 'text' : 'password'}
              size="large"
              sx={{
                backgroundColor: 'white',
                borderRadius: '10px !important',
              }}
              fullWidth
              error={!!errors1}
              helperText={errors1}
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
              onKeyDown={handleEnterSetPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowNewPassword(!shownewPassword)}
                      edge="end"
                    >
                      {shownewPassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <DialogActions sx={{ justifyContent: "center" }}>
              <Button
                sx={{
                  type: "submit",
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#0d2353",
                  "&:hover": {
                    backgroundColor: "#0d65c8",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#5BB4E4",
                    color: "white",
                  },
                  variant: "contained",
                  borderRadius: "6px",
                  mt: 2
                }}
                onClick={handleNewPasswordNextSubmit}
                disabled={loading}
                startIcon={loading ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                      color: '#fff'
                    }} />
                ) : null}
              >
                {loading ? "Please Wait" : "Next"}
              </Button>
            </DialogActions>
            <DialogContentText sx={{ mt: 1, textAlign: "center" }}>
              <span
                style={{
                  color: "gray",
                  cursor: "pointer",
                }}
                onClick={handleChangeEmailPassword}
              >
                Back to login
              </span>
            </DialogContentText>
          </DialogContent>
        ) : enterPassword == "emailpassowrd" ? (
          <DialogContent>
            <Typography>
              Email - {mobileNumber}
            </Typography>
            <DialogContentText sx={{ pb: 1 }}>
              Please Set Your Password
            </DialogContentText>
            <TextField
              id="password"
              name="password"
              placeholder="Password"
              autoFocus
              type={showemailPassword ? 'text' : 'password'}
              size="large"
              sx={{
                backgroundColor: 'white',
                borderRadius: '10px !important',
              }}
              fullWidth
              error={!!errors1}
              helperText={errors1}
              value={emailpassword}
              onChange={(event) => setEmailpassword(event.target.value)}
              onKeyDown={handleEnterSetPasswordBoth}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowemailPassword(!showemailPassword)}
                      edge="end"
                    >
                      {showemailPassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <DialogActions sx={{ justifyContent: "center" }}>

              <Button
                component="div"
                sx={{
                  color: "white",
                  // backgroundColor: "#0d2353",
                  borderRadius: "6px",
                  padding: "8px 18px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "8px",
                  cursor: "pointer",
                  mt: 2,
                  backgroundColor: "#0d2353",
                  "&:hover": {
                    backgroundColor: "#0d65c8",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#5BB4E4",
                    color: "white",
                  },
                  "&:hover .icon": {
                    transform: "translateX(5px)",
                    transition: "transform 0.5s ease", // Smooth animation for the icon
                  },
                }}
                onClick={handleEnterEmailPassword}
                disabled={loading}
                startIcon={loading ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                      color: '#fff'
                    }} />
                ) : null}
              >
                <Box component="span" sx={{ display: "inline-flex", alignItems: "center", gap: "8px" }}>
                  {loading ? "Please Wait" : "Register"}
                  <Icon className="icon" icon="solar:round-arrow-right-bold" width="1.5rem" height="1.5rem" />
                </Box>
              </Button>
            </DialogActions>
            <DialogContentText sx={{ mt: 1, textAlign: "center" }}>
              <span
                style={{
                  color: "gray",
                  cursor: "pointer",
                }}
                onClick={handleChangeEmailPassword}
              >
                Back to login
              </span>
            </DialogContentText>
          </DialogContent>
        ) : null}

      </Dialog>


    </AppBar>
  );
}

export default Navbar;
