import React, { useState, forwardRef, useEffect } from 'react'
import { CircularProgress, Typography, Chip, Card, CardContent, Box, TextField, Button, Divider, ClickAwayListener, Avatar } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import TodayIcon from '@mui/icons-material/Today';
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { styled } from '@mui/material/styles'
import MuiAvatar from '@mui/material/Avatar'
// import CustomAvatar from 'src/@core/components/mui/avatar'
import { getInitials } from '../name'
import EditPost from './EditPost';
import Swal from 'sweetalert2';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImagePreview from './ImagePreview';
import Grid from '@mui/material/Grid';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { jwtDecode } from "jwt-decode";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { format, differenceInDays, isToday, isYesterday } from 'date-fns';
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import useLogout from '../../hooks/logouthook'
import { Icon } from '@iconify/react/dist/iconify.js';


const ForumCard = ({ post, likesArray, handleLike, handleCommentClick, setTableData, fetchReset }) => {

    const [editOpen, setEditOpen] = useState(false)
    const [postObj, setPostObj] = useState(null)
    const [postOpen, setPostOpen] = useState(false)
    const [imagePreview, setImagePreview] = useState(false)
    const [imageUrl, setImageUrl] = useState([])
    let [userID, setUserID] = useState('');

    const { handleLogout } = useLogout();
    const theme = useTheme()
    const isLargeScreen = useMediaQuery(theme.breakpoints.down('sm'))

    let adminId = ''
    if (typeof localStorage !== 'undefined') {
        adminId = window.localStorage.getItem('studentID')
    }

    let token = ''
    if (typeof localStorage !== 'undefined') {
        token = window.localStorage.getItem('token')
    }

    useEffect(() => {
        if (token) {
            const decoded = jwtDecode(token);
            setUserID(decoded.user_id)

        } else {
            console.error('Token is invalid or not found.');
        }

    }, [token])

    const handleEdit = (post) => {
        setEditOpen(!editOpen)

    }

    const handleClose = () => {
        setEditOpen(false)
    }

    const handleDelete = (post) => {

        Swal.fire({
            title: `Delete Post`,
            text: 'Are you sure you want to Delete the post?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#00669e',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            customClass: {
                container: 'custom-swal-container' // Add a custom class to the container
            }
        }).then(async result => {
            if (result.isConfirmed) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/remove-post`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`
                        },
                        body: JSON.stringify({
                            id: post.fro_id
                        })
                    })

                    if (response.status == 403 || response.status == 401) {

                        let refreshToken = ''
                        if (typeof localStorage !== 'undefined') {
                            refreshToken = window.localStorage.getItem('refreshToken')
                        }

                        try {
                            const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    refreshToken: refreshToken
                                })
                            })
                            if (tokenResponse.status == 200) {
                                const tokendata = await tokenResponse.json()
                                window.localStorage.setItem('token', tokendata.accessToken)
                                token = tokendata.accessToken
                                handleDelete(post)
                            } else {
                                handleLogout();
                            }
                        } catch (error) {
                            console.error(error)
                        }
                    }
                    if (response.status == 200) {
                        Swal.fire({
                            title: 'Confirmed!',
                            icon: 'success',
                            confirmButtonColor: '#00669e',
                            customClass: {
                                container: 'custom-swal-container' // Add a custom class to the container
                            }
                        })
                        setTableData((prevState) =>
                            prevState.filter((item) => item.fro_id !== post.fro_id)
                        )
                    }
                } catch (error) {
                    console.error(error)
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire({
                    title: 'Cancelled',
                    icon: 'error',
                    confirmButtonColor: '#00669e',
                    customClass: {
                        container: 'custom-swal-container' // Add a custom class to the container
                    }
                })
            }
        })
    }

    const handlePostedit = (post) => {
        setPostOpen(true)
        if (post) {
            setPostObj(post)
        }
        setEditOpen(false)
    }

    const handleImagePreview = (img) => {
        setImagePreview(true)
        setImageUrl(img)
    }

    function formatDate(date) {
        const postDate = new Date(date);
        const today = new Date();

        const daysDifference = differenceInDays(today, postDate);

        if (isToday(postDate)) {
            return format(postDate, 'p'); // Show time only (e.g., 2:30 PM)
        } else if (isYesterday(postDate)) {
            return 'Yesterday';
        } else if (daysDifference <= 6) {
            return `${daysDifference} days ago`;
        } else {
            return format(postDate, 'yyyy-MM-dd'); // Show full date (e.g., 2023-09-07)
        }
    }

    const WordLimit = isLargeScreen ? 50 : 75;
    const words = post?.text?.split(' ');
    const isLongPost = words?.length > WordLimit;
    const truncatedText = isLongPost ? words.slice(0, WordLimit).join(' ') : post.text;

    const titleshort = (texts) => {
        if (!texts) return '';
        return texts?.length > 10 ? texts?.substring(0, 20) + '...' : texts;
    }

    const generateUrl = () => {
        const title = post.title ? cleanTextForUrl(post.title) : '';
        const paragraphText = cleanTextForUrl(post.text);

        if (post.title) {
            // Use the title
            return `/community_knowledge/${post.fro_id}/${title?.replace(/\s+/g, '-').toLowerCase()}`;
        } else if (post.text.length > 9) {
            // Use the first 10 words of the paragraph
            const first10Words = paragraphText.split('-').slice(0, 15).join(' ');
            return `/community_knowledge/${post.fro_id}/${first10Words?.replace(/\s+/g, '-').toLowerCase()}`;
        } else {
            // Fallback to student ID and name
            if (post.type == 'student') {
                return `/community_knowledge/${post.fro_id}/${post.additionalInfo?.stu_id}-${post.additionalInfo?.stu_fname?.replace(/\s+/g, '-').toLowerCase()}`;
            } else if (post.type == 'teacher') {

                return `/community_knowledge/${post.fro_id}/${post.additionalInfo?.t_id}-${post.additionalInfo?.t_fname?.replace(/\s+/g, '-').toLowerCase()}`;

            }
        }
    };

    const cleanTextForUrl = (text) => {
        // Strip HTML tags
        const stripHtmlTags = (html) => {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = html;
            return tempDiv.innerText || tempDiv.textContent || '';
        };

        // Get plain text without HTML tags
        const plainText = stripHtmlTags(text);

        // Encode special characters and replace spaces with hyphens
        return plainText
            .replace(/[^a-zA-Z0-9\s-]/g, '') // Remove special characters
            .trim()
            .replace(/\s+/g, '-') // Replace spaces with hyphens
            .toLowerCase(); // Convert to lowercase
    };

    return (
        <div>

            <Box sx={{}}>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        {post.title && post.type == 'student' ? (

                            <a href={generateUrl()}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none', color: 'black' }} >
                                <Typography sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <Icon icon="clarity:help-solid-badged" width="1.3rem" height="1.3rem" style={{ marginRight: 3 }} />
                                    {post?.additionalInfo?.stu_fname} {post.title}
                                </Typography>
                            </a>

                        ) : (

                            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }} >
                                <a href={generateUrl()}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: 'none', color: 'black' }} >
                                    <Typography sx={{ pb: 2, fontSize: '20px', fontWeight: 600 }}>
                                        {isLargeScreen ? titleshort(post?.title) : post?.title}
                                    </Typography>
                                </a>

                            </Box>

                        )}
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {post.type == 'student' && post.s_id == userID && (
                            <Box sx={{ position: 'relative' }}>
                                <IconButton
                                    aria-label='close'
                                    onClick={() => handleEdit(post)}
                                    sx={{ p: 0 }}
                                >
                                    <MoreHorizIcon />
                                </IconButton>

                                {editOpen && (
                                    <ClickAwayListener onClickAway={handleClose}>

                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: 30,
                                                right: 0,
                                                bgcolor: 'background.paper',
                                                boxShadow: 5,
                                                borderRadius: 1,
                                                p: 1,
                                                minWidth: 100,
                                                zIndex: 1
                                            }}
                                        >

                                            <Box
                                                onClick={() => handlePostedit(post)}
                                                sx={{ p: 1, '&:hover': { bgcolor: 'action.hover', cursor: 'pointer' } }}
                                            >
                                                <Typography variant="body2">Edit</Typography>
                                            </Box>
                                            <Box
                                                onClick={() => handleDelete(post)}
                                                sx={{ p: 1, '&:hover': { bgcolor: 'action.hover', cursor: 'pointer' } }}
                                            >
                                                <Typography variant="body2">Delete</Typography>
                                            </Box>
                                        </Box>
                                    </ClickAwayListener>
                                )}
                            </Box>
                        )}
                    </Box>

                </Box>

                {/* {isLargeScreen && (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Box>
                                {post.type == 'student' ? (
                                    <>
                                        {(post?.additionalInfo?.profile) ? (
                                            <MuiAvatar
                                                src={post?.additionalInfo?.profile}
                                                alt={`${post.additionalInfo?.stu_fname} ${post.additionalInfo?.stu_lname}`}
                                                sx={{ width: 40, height: 40 }}
                                            />
                                        ) : (
                                            <Avatar
                                                skin='light'
                                                sx={{ width: 40, height: 40, fontSize: '1rem', textTransform: 'uppercase' }}
                                            >
                                                {getInitials(`${post.additionalInfo?.stu_fname} ${post.additionalInfo?.stu_lname}`)}
                                            </Avatar>
                                        )}
                                    </>
                                ) : post.type == 'teacher' ? (
                                    <>
                                        {(post?.additionalInfo?.profile) ? (
                                            <MuiAvatar
                                                src={post?.additionalInfo?.profile}
                                                alt={`${post.additionalInfo?.t_fname} ${post.additionalInfo?.t_lname}`}
                                                sx={{ width: 40, height: 40 }}
                                            />
                                        ) : (
                                            <Avatar
                                                skin='light'
                                                sx={{ width: 40, height: 40, fontSize: '1rem', textTransform: 'uppercase' }}
                                            >
                                                {getInitials(`${post.additionalInfo?.t_fname} ${post.additionalInfo?.t_lname}`)}
                                            </Avatar>
                                        )}
                                    </>
                                ) : (
                                    <MuiAvatar
                                        src='/images/et_logo.png'
                                        alt='admin'
                                        sx={{ width: 40, height: 40 }}
                                    />
                                )}

                            </Box>
                            <Box>
                                {post.type == 'student' && (
                                    <Typography sx={{ fontSize: 12, fontWeight: 600, textTransform: 'capitalize' }}>{post.additionalInfo ? post.additionalInfo?.stu_fname : ''} {post.additionalInfo ? post.additionalInfo?.stu_lname : ''}</Typography>
                                )}
                                {post.type == 'teacher' && (
                                    <Typography sx={{ fontSize: 12, fontWeight: 600, textTransform: 'capitalize' }}>{post.additionalInfo ? post.additionalInfo?.t_fname : ''} {post.additionalInfo ? post.additionalInfo?.t_lname : ''}</Typography>
                                )}
                                {post.type == 'admin' && (
                                    <Typography sx={{ fontSize: 12, fontWeight: 600, textTransform: 'capitalize' }}>EnglishTeacher.lk</Typography>
                                )}
                                <Box sx={{ display: 'flex', gap: 1 }}>

                                    {post.type == 'teacher' && (
                                        <Typography sx={{ fontSize: 12, fontWeight: 600, textTransform: 'capitalize' }}>Teacher</Typography>
                                    )}
                                    <Typography sx={{ fontSize: 12, fontWeight: 600, textTransform: 'capitalize', color: '#4c4e6499' }}> {formatDate(post.date_time)}</Typography>
                                </Box>

                            </Box>
                        </Box>


                        <Box sx={{}}>

                            {post.type == 'student' && post.s_id == userID && (
                                <Box sx={{ position: 'relative' }}>
                                    <IconButton
                                        aria-label='close'
                                        onClick={() => handleEdit(post)}
                                        sx={{}}
                                    >
                                       
                                        <MoreHorizIcon />
                                    </IconButton>
                                    {editOpen && (
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: 30,
                                                    right: 0,
                                                    bgcolor: 'background.paper',
                                                    boxShadow: 5,
                                                    borderRadius: 1,
                                                    p: 0.5,
                                                    minWidth: 100,
                                                    zIndex: 1
                                                }}
                                            >
                                                <Box
                                                    onClick={() => handlePostedit(post)}
                                                    sx={{ p: 0.5, '&:hover': { bgcolor: 'action.hover', cursor: 'pointer' } }}
                                                >
                                                    <Typography variant="body2">Edit</Typography>
                                                </Box>
                                                <Box
                                                    onClick={() => handleDelete(post)}
                                                    sx={{ p: 0.5, '&:hover': { bgcolor: 'action.hover', cursor: 'pointer' } }}
                                                >
                                                    <Typography variant="body2">Delete</Typography>
                                                </Box>
                                            </Box>
                                        </ClickAwayListener>
                                    )}
                                </Box>
                            )}
                        </Box>

                    </Box>
                )} */}

                {(isLargeScreen || post.title || (post.type == 'student' && post.s_id == userID)) && (
                    <Divider sx={{ my: 1 }} />
                )}

                {post.text.length > 9 && (
                    <Box className="post-content" dangerouslySetInnerHTML={{ __html: truncatedText }} sx={{
                        img: {
                            maxWidth: '100%', // Prevent image overflow
                            height: 'auto',   // Maintain aspect ratio
                        },
                        iframe: {
                            maxWidth: '100%', // Make the video responsive
                            height: 'auto', // Maintain the aspect ratio
                            minHeight: '300px', // Set a minimum height for better appearance
                        },
                         borderRadius:'8px',
                                            backgroundColor:'#f7f4f4'
                    }}></Box>
                )}

                {isLongPost && (
                    <Box sx={{ textAlign: 'right' }}>
                        <a href={generateUrl()}
                            target="_blank"
                            rel="noopener noreferrer" >
                            <Button
                                sx={{ p: 0, mt: -10, fontSize: 12 }}
                                // onClick={() => handleCommentClick(post)}
                                size="small"

                            >
                                ...Read More
                            </Button>
                        </a>
                    </Box>
                )}


                {post?.img.length == 1 ? (
                    <Box sx={{ width: '100%', paddingBottom: '56.25%', mt: 1, borderRadius: 0.5, backgroundColor: '', position: 'relative', overflow: 'hidden', cursor: 'pointer' }} onClick={() => handleImagePreview(post?.img)}>

                        <img
                            src={post?.img[0]}
                            alt='post'
                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'contain', borderRadius: 0.5 }} // Ensure image fills the container
                        />
                    </Box>
                ) : post?.img.length > 1 ? (
                    <Box sx={{ width: '100%', mt: 1 }}>
                        <Grid container rowSpacing={1} columnSpacing={2}>
                            {post?.img.map((item, index) => (
                                <Grid item xs={12} sm={6} key={index} onClick={() => handleImagePreview(post?.img)}>
                                    <img

                                        src={item}
                                        alt={index}
                                        loading="lazy"
                                        style={{ objectFit: 'cover', width: '100%', height: 200, cursor: 'pointer' }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                ) : null}

                {post.url2 && (
                    <Box className="youtube-container" sx={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', background: '#000', mt: 1 }}>
                        <iframe
                            title="YouTube video"
                            src={post.url2}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                        />
                    </Box>
                )}


                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1.5 }}>
                        <Box>
                            {post.type == 'student' ? (
                                <>
                                    {(post?.additionalInfo?.profile) ? (
                                        <MuiAvatar
                                            src={post?.additionalInfo?.profile}
                                            alt={`${post.additionalInfo?.stu_fname} ${post.additionalInfo?.stu_lname}`}
                                            sx={{ width: 40, height: 40 }}
                                        />
                                    ) : (
                                        <Avatar
                                            skin='light'
                                            sx={{ width: 40, height: 40, fontSize: '1rem', textTransform: 'uppercase' }}
                                        >
                                            {getInitials(`${post.additionalInfo?.stu_fname} ${post.additionalInfo?.stu_lname}`)}
                                        </Avatar>
                                    )}
                                </>
                            ) : post.type == 'teacher' ? (
                                <>
                                    {(post?.additionalInfo?.profile) ? (
                                        <MuiAvatar
                                            src={post?.additionalInfo?.profile}
                                            alt={`${post.additionalInfo?.t_fname} ${post.additionalInfo?.t_lname}`}
                                            sx={{ width: 40, height: 40 }}
                                        />
                                    ) : (
                                        <Avatar
                                            skin='light'
                                            sx={{ width: 40, height: 40, fontSize: '1rem', textTransform: 'uppercase' }}
                                        >
                                            {getInitials(`${post.additionalInfo?.t_fname} ${post.additionalInfo?.t_lname}`)}
                                        </Avatar>
                                    )}
                                </>
                            ) : (
                                <MuiAvatar
                                    src='/images/et_logo.png'
                                    alt='admin'
                                    sx={{ width: 40, height: 40 }}
                                />
                            )}

                        </Box>
                        <Box>
                            {post.type == 'student' && (
                                <Typography sx={{ fontSize: 12, fontWeight: 600, textTransform: 'capitalize' }}>{post.additionalInfo ? post.additionalInfo?.stu_fname : ''} {post.additionalInfo ? post.additionalInfo?.stu_lname : ''}</Typography>
                            )}
                            {post.type == 'teacher' && (
                                <Typography sx={{ fontSize: 12, fontWeight: 600, textTransform: 'capitalize' }}>{post.additionalInfo ? post.additionalInfo?.t_fname : ''} {post.additionalInfo ? post.additionalInfo?.t_lname : ''}</Typography>
                            )}
                            {post.type == 'admin' && (
                                <Typography sx={{ fontSize: 12, fontWeight: 600, textTransform: 'capitalize' }}>EnglishTeacher.lk</Typography>
                            )}
                            <Box sx={{ display: 'flex', gap: 1 }}>

                                {post.type == 'teacher' && (
                                    <Typography sx={{ fontSize: 12, fontWeight: 600, textTransform: 'capitalize' }}>Teacher</Typography>
                                )}
                                <Typography sx={{ fontSize: 12, fontWeight: 600, textTransform: 'capitalize', color: '#4c4e6499' }}> {formatDate(post.date_time)}</Typography>
                            </Box>

                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row-reverse', gap: 1, mt: 1.5 }}>
                        {post.admin_status == 0 ? (
                            <Chip size='small' label={'Approval Pending'} color='warning' />
                        ) : (
                            <>
                                <a href={generateUrl()}
                                    target="_blank"
                                    rel="noopener noreferrer" >
                                    <Chip icon={<ChatBubbleOutlineOutlinedIcon />} label={post.comments}

                                    />
                                </a>
                                <Chip icon={likesArray.some(item => item.fro_id === post.fro_id) ? <FavoriteIcon color='error' /> : <FavoriteBorderOutlinedIcon />} label={post.det_like} onClick={() => handleLike(post)} />
                            </>
                        )}
                    </Box>

                </Box>

            </Box>

            <EditPost setPostOpen={setPostOpen} postOpen={postOpen} postObj={postObj} setTableData={setTableData} fetchReset={fetchReset} />
            <ImagePreview imagePreview={imagePreview} setImagePreview={setImagePreview} imgUrls={imageUrl} />
        </div>
    )
}

export default ForumCard